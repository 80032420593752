import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useUserPreferences } from 'century-core/user-preferences/UserPreferencesContext'; // eslint-disable-line import/no-restricted-paths
import { useRef } from 'react';
import { formatSeconds } from '../utils/helpers';
import { VideoPlayerProps } from '@ctek/design-system';
import mixpanel from 'mixpanel-browser';

const STORAGE_KEY_SUBTITLES_PREFERENCE = 'ctek.subtitles-preference';

export const useSubtitleMode = (ref: React.ForwardedRef<VideoPlayerProps['ref']>, videoId: string, playedSeconds: number) => {
  const userPreferences = useUserPreferences();

  const lastIsEnabledValue = useRef<boolean>(false);

  const trackSubtitleModeChange = (isEnabled: boolean) => {
    // This ensures that mixpanel only fires once per change
    if (lastIsEnabledValue.current !== isEnabled) {
      lastIsEnabledValue.current = isEnabled;
      mixpanel.track(MixpanelEventTypes.ContentMediaVideoSubtitlesChanged, {
        [MixpanelKeys.IsEnabled]: isEnabled,
        [MixpanelKeys.VideoId]: videoId,
        [MixpanelKeys.VideoTimestamp]: formatSeconds(playedSeconds),
      });
    }
  };

  const getSubtitlesMode = (): boolean => {
    const storedValue = userPreferences.readLocal(STORAGE_KEY_SUBTITLES_PREFERENCE);
    const previousValue = storedValue ? JSON.parse(storedValue) : null;
    const textTracks = ref && 'current' in ref && ref.current?.getInternalPlayer()?.textTracks;

    if (textTracks && textTracks.length > 0) {
      const isEnabled = textTracks?.[0]?.mode === 'showing';

      if (previousValue === null || previousValue !== isEnabled) {
        userPreferences.writeLocal(STORAGE_KEY_SUBTITLES_PREFERENCE, JSON.stringify(isEnabled));
        trackSubtitleModeChange(isEnabled);
      }
      return isEnabled;
    }

    return previousValue ?? false;
  };

  return getSubtitlesMode();
};
