import { AssignmentFormData, LateSubmissionOption } from './helpers';
import { AssignmentPayload, AssignmentSchedule, DuplicateAssignmentGroupPayload } from 'century-core/core-apis/assignment/types';
import { mergeAndFormatDateTime } from 'century-core/core-utils/utils/date/date';
import { booleanOrUndefined } from 'century-core/core-utils/utils/utils';
import { sanitize } from 'dompurify';

export const DAY_TO_SECONDS = 86_400;

export const buildAssignmentPayload = (data: AssignmentFormData, classId?: string): AssignmentPayload => {
  return {
    assignment: {
      classId,
      name: sanitize(data.assignmentName.trim()),
      subjectId: data.subject,
      instructions: sanitize(data.instructions.trim()),
      schedule: buildSchedule(data),
      options: { reattemptsAllowed: booleanOrUndefined(data.multipleAttempts) as boolean },
    },
  };
};

export const buildDuplicateAssignmentGroupPayload = (
  data: AssignmentFormData,
  nuggetIds: string[],
  groupIds?: string[]
): DuplicateAssignmentGroupPayload => ({
  assignment: {
    name: sanitize(data.assignmentName.trim()),
    subjectId: data.subject,
    instructions: sanitize(data.instructions.trim()),
    schedule: buildSchedule(data),
    options: { reattemptsAllowed: booleanOrUndefined(data.multipleAttempts) as boolean },
    nuggetIds,
    groupIds,
  },
});

const mapLateSubmissionToSeconds = (option: LateSubmissionOption): number => {
  if (option === 'no') return 0;
  return Number(option) * DAY_TO_SECONDS;
};

const buildSchedule = (data: AssignmentFormData): AssignmentSchedule => {
  return {
    startAt: mergeAndFormatDateTime(data.startDate, data.startTime),
    dueAt: mergeAndFormatDateTime(data.dueDate, data.dueTime),
    closeKind: data.lateSubmission === 'no' ? 'None' : 'Duration',
    closeDurationSeconds: mapLateSubmissionToSeconds(data.lateSubmission),
  };
};
