import { Widget, WidgetList } from '@ctek/design-system';
import { useState, useMemo, useCallback, useEffect } from 'react';
import CoursesWidgetCourseCard from './CoursesWidgetCourseCard';
import CoursesWidgetCourseCardMobile from './CoursesWidgetCourseCardMobile';
import PaginationWidget from '../../../../core-components/Pagination/PaginationWidget';
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { coursesWidgetQuery } from './coursesWidget.graphql';
import { useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { useQuery } from '@apollo/client';
import { SUBJECT_COURSE_LABEL_ID, STUDENT_DASHBOARD_HIDDEN_LEGACY_COURSE } from 'century-core/core-utils/utils/behaviours';
import mixpanel from 'mixpanel-browser';

const NUM_ITEMS_PER_PAGE = 20;

interface Props {
  userId: string;
  coursesData?: Ctek.Roentgen.CoursePathItem[];
  onDataFetch?: (...params: any) => void;
  subjectIds?: string[];
  subjectGroupIds?: string[];
  testPracticeFilter?: TestPracticeFilter;
  widgetMode: DashboardUserMode;
  isBondLearner?: boolean;
}

export default function CoursesWidgetBody(props: Props) {
  const intl = useIntl();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const { subjectIds, subjectGroupIds, testPracticeFilter, isBondLearner } = props;
  const { deviceType } = useDeviceInfo();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseUrl = useMemo(() => location.pathname.slice(0, location.pathname.lastIndexOf('/')), []);

  const goToPage = useCallback(
    (page: number) => {
      if (pageNumber !== page) {
        setPageNumber(page);
      }
    },
    [pageNumber]
  );

  const queryParams = useMemo(
    () => ({
      input: {
        userId: props.userId,
        courseTypes: ['standard'],
        mode: 'linear-complete',
        offset: (pageNumber - 1) * NUM_ITEMS_PER_PAGE,
        limit: NUM_ITEMS_PER_PAGE,
        subjectIds,
        subjectGroupIds,
        testPrepFilterSelector: testPracticeFilter,
      },
    }),
    [pageNumber, props.userId, subjectGroupIds, subjectIds, testPracticeFilter]
  );

  const query = useQuery(coursesWidgetQuery, {
    variables: queryParams,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // if you've got an error you can still potentially show remaining widgets
    // TODO improve loading and error states on the dashboard
    if ((typeof query?.data?.coursePath?.count === 'number' || query.error) && props.onDataFetch) {
      props.onDataFetch(!!query.error || query.data.coursePath.count > 0);
    }
  }, [query.data, query.error, props]);

  useEffect(() => {
    setPageNumber(1);
  }, []);

  const maxPages = useMemo(() => (query.data?.coursePath ? Math.ceil(query.data.coursePath.count / NUM_ITEMS_PER_PAGE) : 0), [query.data]);

  const handleNextPage = () => {
    if (pageNumber < maxPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isEmpty = useMemo(() => !query.data?.coursePath?.courses.length, [query.data]);

  const emptyState = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'courses-widget-empty-title', defaultMessage: 'No Courses' }),
      message: intl.formatMessage({ id: 'courses-widget-empty-body', defaultMessage: 'You have no courses to display' }),
    }),
    [intl]
  );

  const sendMixpanel = (id: string) => {
    if (props.widgetMode === DashboardUserMode.STUDENT) {
      return mixpanel.track(MixpanelEventTypes.StudentDashboardCourseClicked, { [MixpanelKeys.CourseId]: id });
    }
    if (props.widgetMode === DashboardUserMode.GUARDIAN) {
      return mixpanel.track(MixpanelEventTypes.GuardianDashboardCourseClicked, {
        [MixpanelKeys.CourseId]: id,
        [MixpanelKeys.DependantId]: props.userId,
      });
    }
    if (props.widgetMode === DashboardUserMode.TEACHER) {
      return mixpanel.track(MixpanelEventTypes.TeacherDashboardStudentOverviewCourseClicked, {
        [MixpanelKeys.CourseId]: id,
        [MixpanelKeys.StudentId]: props.userId,
      });
    }
  };

  const persistQueryString = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('courseId');
    return searchParams.toString();
  }, [location]);

  // LX PHASE 1 - initially only show unified version to Bond users. We need to show first the megacourses.
  const sortedFilteredCourses = useMemo(() => {
    if (!isBondLearner) {
      return query.data?.coursePath?.courses;
    }

    const labelCourses: Ctek.Roentgen.CoursePathItem[] = [];
    const nonLabelCourses: Ctek.Roentgen.CoursePathItem[] = [];
    query.data?.coursePath?.courses.forEach((course: Ctek.Roentgen.CoursePathItem) => {
      if (!course.labels?.find((courseLabel: string) => courseLabel === STUDENT_DASHBOARD_HIDDEN_LEGACY_COURSE)) {
        if (course.labels?.find((courseLabel: string) => courseLabel === SUBJECT_COURSE_LABEL_ID)) {
          labelCourses.push(course);
        } else {
          nonLabelCourses.push(course);
        }
      }
    });

    return [...labelCourses, ...nonLabelCourses];
  }, [isBondLearner, query.data?.coursePath?.courses]);

  return (
    <Widget.Body isScrollable={true}>
      <QueryWrapper query={query} emptyState={emptyState} isEmpty={isEmpty}>
        <WidgetList>
          {sortedFilteredCourses?.map((course: Ctek.Roentgen.CoursePathItem, i: number) => {
            const relativePath = isBondLearner
              ? 'subjects'
              : props.testPracticeFilter === TestPracticeFilter.TEST_PRACTICE_ONLY
              ? 'test-practice'
              : 'courses';
            return (
              <WidgetList.Item key={course.courseId}>
                <Link
                  key={`link-${course.courseId}`}
                  to={`${baseUrl}/${relativePath}?courseId=${course.courseId}${persistQueryString ? `&${persistQueryString}` : ''}`}
                  onClick={() => sendMixpanel(course.courseId)}
                >
                  {deviceType !== 'phone' ? <CoursesWidgetCourseCard course={course} /> : <CoursesWidgetCourseCardMobile course={course} />}
                  {/* <CardGroupTitle currentCourse={course} prevCourse={query.data.coursePath.courses[i - 1]} index={i} /> */}
                </Link>
              </WidgetList.Item>
            );
          })}
        </WidgetList>
        {query.data?.coursePath?.count > NUM_ITEMS_PER_PAGE && (
          <PaginationWidget
            maxPages={maxPages}
            onNextClick={handleNextPage}
            onPrevClick={handlePrevPage}
            currentPage={pageNumber}
            goToPage={goToPage}
          />
        )}
      </QueryWrapper>
    </Widget.Body>
  );
}
