import { useIntl } from 'react-intl';

export const useAssignmentLabelsTranslation = () => {
  const { formatMessage } = useIntl();
  return {
    assignmentName: formatMessage({ id: 'assignment-name-label', defaultMessage: 'Name' }),
    subject: formatMessage({ id: 'assignment-subject-label', defaultMessage: 'Subject' }),
    instructions: formatMessage({ id: 'assignment-instructions-label', defaultMessage: 'Instructions' }),
    startDate: formatMessage({ id: 'assignment-start-date-label', defaultMessage: 'Start Date' }),
    startTime: formatMessage({ id: 'assignment-start-time-label', defaultMessage: 'Start Time' }),
    dueDate: formatMessage({ id: 'assignment-due-date-label', defaultMessage: 'Due Date' }),
    dueTime: formatMessage({ id: 'assignment-due-time-label', defaultMessage: 'Due Time' }),
    lateSubmission: formatMessage({ id: 'assignment-late-submission-label', defaultMessage: 'Late Submission' }),
    multipleAttempts: formatMessage({ id: 'assignment-multiple-attempts-label', defaultMessage: 'Multiple Attempts' }),
    lastUpdatedBy: formatMessage({ id: 'assignment-last-updated-by-label', defaultMessage: 'Last Updated By' }),
    lastUpdatedAt: formatMessage({ id: 'assignment-last-updated-at-label', defaultMessage: 'Last Updated At' }),
    unknownField: formatMessage({ id: 'unknown-field', defaultMessage: 'Unknown' }),
  };
};
