import { CompletionNugget, CompletionNuggetDetailsResult } from 'century-core/core-apis/learn/aggregations/aggregations';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';

export const getLabelString = (
  lastResult: CompletionNuggetDetailsResult,
  bestResult: number,
  averageResult: number,
  nugget: CompletionNugget,
  intl: IntlShape
): string => {
  const labelFields: string[] = [nugget.name];
  const date = DateTime.fromISO(lastResult.date).toFormat('dd MMM yyyy');
  labelFields.push(
    intl.formatMessage(
      {
        id: 'markbook--last-score',
        defaultMessage: `Last Score: ${lastResult.score}% on ${date}`,
      },
      { lastResultScore: lastResult.score, lastResultDate: date }
    )
  );
  if (bestResult) {
    labelFields.push(
      intl.formatMessage(
        {
          id: 'markbook--best-score',
          defaultMessage: `Best Score: ${bestResult}%`,
        },
        { bestResultScore: bestResult }
      )
    );
  }
  if (averageResult) {
    labelFields.push(
      intl.formatMessage(
        {
          id: 'markbook--average-score',
          defaultMessage: `Average Score: ${averageResult}%`,
        },
        { averageResultScore: averageResult }
      )
    );
  }
  return labelFields.join('\n');
};
