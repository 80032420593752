import { useCallback, useEffect, useRef } from 'react';
import { useDataLoader } from './use-data-loader';
import usePagination from './use-pagination';
import { SortSettings } from 'century-core/core-utils/utils/tableSorting/tableSorting';
import { PaginatedRequest, PaginatedResult } from 'century-core/core-utils/shared/shared.types';

type UsePaginationDataLoaderOptions = {
  itemsPerPage: number;
  orderBy?: string;
  sortBy?: 'asc' | 'desc';
  allowedSortColumns?: string[];
  syncWithUrl?: boolean;
};

export type UsePaginationDataLoaderReturn<T> = {
  data: T | null;
  loading: boolean;
  error: Error | undefined;
  currentPage: number;
  totalPages: number;
  defaultSortSettings: SortSettings;
  load: () => void;
  loadNextPage: () => void;
  loadPreviousPage: () => void;
  loadSpecificPage: (page: number) => void;
  handleSortingState: (column: string, direction: 'asc' | 'desc') => void;
  resetPageNumber: () => void;
};

export const usePaginatedDataLoader = <T extends PaginatedResult<any>>(
  request: (token: string, opts: PaginatedRequest, signal: AbortSignal) => Promise<T>,
  options: UsePaginationDataLoaderOptions,
  extraParams?: Record<string, any>
): UsePaginationDataLoaderReturn<T> => {
  const pageTokenRef = useRef<string | null>(null);
  const sortColumnRef = useRef<string | undefined>(options.orderBy);
  const sortDirectionRef = useRef<'asc' | 'desc' | undefined>(options.sortBy);

  const requestWithParams = useCallback(async (token: string, signal: AbortSignal) => {
      const opts: PaginatedRequest & Record<string, any> = {
        ...extraParams,
        pageSize: options.itemsPerPage,
        pageToken: pageTokenRef.current || undefined,
        page: pageNumberRef.current,
        order: sortColumnRef.current || undefined,
        sort: sortDirectionRef.current as 'asc' | 'desc',
      };
      return await request(token, opts, signal);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[extraParams, options.itemsPerPage, request]);

  const { data, loading, error, load } = useDataLoader<T>(requestWithParams);

  const {
    currentPage,
    totalPages,
    goToPage,
    handleNextPage,
    handlePreviousPage,
    sortColumn,
    sortDirection,
    defaultSortSettings,
    handleSortingState,
  } = usePagination({
    ...options,
    totalItems: data?.count || 0,
  });

  const pageNumberRef = useRef(currentPage); 

  useEffect(() => {
    sortColumnRef.current = sortColumn || options.orderBy;
    sortDirectionRef.current = sortDirection;
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, sortDirection]);

  const loadNextPage = useCallback(() => {
    pageTokenRef.current = data?.nextPageToken || null;
    pageNumberRef.current += 1;
    handleNextPage();
    load();
  }, [handleNextPage, load, data?.nextPageToken]);

  const loadPreviousPage = useCallback(() => {
    pageTokenRef.current = data?.previousPageToken || null;
    pageNumberRef.current -= 1;
    handlePreviousPage();
    load();
  }, [handlePreviousPage, load, data?.previousPageToken]);

  const loadSpecificPage = useCallback((page: number) => {
      goToPage(page);
      pageNumberRef.current = page;
      load();
    },
    [goToPage, load]
  );

  const resetPageNumber = useCallback(() => {
    pageNumberRef.current = 1;
    pageTokenRef.current = null;
    goToPage(1);
  }, [goToPage]);

  return {
    data: data || null,
    loading,
    error,
    currentPage,
    totalPages,
    defaultSortSettings,
    load,
    loadNextPage,
    loadPreviousPage,
    loadSpecificPage,
    handleSortingState,
    resetPageNumber,
  };
};
