import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import { Button, NuggetRecommendation, ProgressThumbnail, NuggetInfo, Heading, Text, NuggetTypeIcons } from '@ctek/design-system';
import CourseImage from 'century-core/media/CourseImage';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { getDayCountFromToday } from 'century-core/core-utils/utils/date/date';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { SubjectColouredLabel } from 'century-core/core-components/Subject/SubjectLabel';
import mixpanel from 'mixpanel-browser';

interface Props {
  nugget: Ctek.Roentgen.NuggetPathItem;
  widgetMode: DashboardUserMode;
}

const convertOrderingFactorToEnumKeyFormat = (orderingFactor: string) => orderingFactor.split('-').join('_').toUpperCase();

const RecommendedNuggetCard = ({ nugget, widgetMode }: Props) => {
  const history = useHistory();

  const startNugget = () => {
    mixpanel.track(MixpanelEventTypes.StudentDashboardSuggestedNuggetClicked, { [MixpanelKeys.NuggetId]: nugget.nugget.nuggetId });
    history.push({
      pathname: `/learn/my-dashboard/overview/study-groups/${nugget.courses[0].studyGroupIds[0].studyGroupId}/nuggets/${nugget.nugget.nuggetId}/`,
      state: { reason: nugget.orderingFactorMainContributor.orderingFactorType, viewLocation: 'StudentDashboard' },
      search: `startedFrom=${nugget.orderingFactorMainContributor.orderingFactorType}`,
    });
  };

  const title = useMemo(() => {
    return nugget.orderingFactorMainContributor.orderingFactorType === 'rlp-orderingfactor-failureadaptation' ? (
      <FormattedMessage id="rlp-orderingfactor-failureadaptation" defaultMessage="Focus on" />
    ) : (
      <FormattedMessage id="rlp-orderingfactor-spacedlearning" defaultMessage="Memory Boost" />
    );
  }, [nugget.orderingFactorMainContributor]);

  const percentScore = useMemo(() => {
    return getPercentValue(nugget.lastResult!.percentScore);
  }, [nugget.lastResult]);

  const percentComplete = useMemo(() => {
    return getPercentValue(nugget.lastResult!.percentComplete);
  }, [nugget.lastResult]);

  return (
    <>
      <NuggetRecommendation
        icon={NuggetTypeIcons[convertOrderingFactorToEnumKeyFormat(nugget.orderingFactorMainContributor.orderingFactorType)]}
        qa="widget--recommended-nugget--title"
      >
        <NuggetRecommendation.Title>{title}</NuggetRecommendation.Title>
      </NuggetRecommendation>
      <NuggetInfo qa="widget--nugget-recommendation--info">
        <NuggetInfo.Thumbnail>
          <ProgressThumbnail size={90} completionPercentage={percentComplete} scorePercentage={percentScore}>
            <CourseImage
              item={{
                iconCode: nugget.courses[0].icon.iconCode,
                iconUrl: nugget.courses[0].icon.url,
                subject: {
                  _id: nugget.courses[0].subject.labelId,
                  name: nugget.courses[0].subject.name,
                },
              }}
            />
          </ProgressThumbnail>
        </NuggetInfo.Thumbnail>
        <NuggetInfo.Main>
          <SubjectColouredLabel subjectName={nugget.courses[0].subject.name} />
          <Heading.ItemTitle>{nugget.nugget.name}</Heading.ItemTitle>
          <Text.Label>
            {nugget.orderingFactorMainContributor.orderingFactorType === 'rlp-orderingfactor-spacedlearning' && (
              /* memory boost */
              <FormattedMessage
                id="learner-analytics--recommended-nugget--last-attempt-time"
                defaultMessage="Days since last attempt: {dayCount}"
                values={{
                  dayCount: Math.abs(getDayCountFromToday(nugget.lastResult!.endTime)),
                }}
              />
            )}
            {nugget.orderingFactorMainContributor.orderingFactorType === 'rlp-orderingfactor-failureadaptation' && (
              /* focus to improve */
              <FormattedMessage
                id="learner-analytics--recommended-nugget--last-attempt-score"
                defaultMessage="Last Score: {score}%"
                values={{
                  score: percentScore,
                }}
              />
            )}
          </Text.Label>
          {widgetMode === DashboardUserMode.STUDENT && (
            <Button onClick={startNugget} variant="primary" size="small" qa="learner-analytics--recommended-nuggets-widget--start">
              <FormattedMessage id="start" defaultMessage="start" />
            </Button>
          )}
        </NuggetInfo.Main>
      </NuggetInfo>
    </>
  );
};

export default RecommendedNuggetCard;
