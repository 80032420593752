import { FetchErrorWithData } from 'century-core/core-apis/fetch';
import { GET, POST, PUT, DELETE, setUrlSearchParams } from 'century-core/core-apis/utils';
import { getAssignmentUrl } from './assignmentV1';
import { AssignmentDetails, AssignmentGroup, AssignmentPayload, AssignmentsData, DuplicateAssignmentGroupPayload } from './types';
import { PaginatedResult } from 'century-core/core-utils/shared/shared.types';
import { addNugget } from './nugget';

export function getAllAssignments(token: string, opts: Record<string, any>, signal: AbortSignal) {
  const url = getAssignmentUrl('/assignments');
  setUrlSearchParams(url, opts);

  return GET<PaginatedResult<AssignmentsData>>({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw err.errorData || Error(`Failed to get assignments: ${err.message}`);
  });
}

export function createNewAssignment(token: string, body: AssignmentPayload, signal?: AbortSignal) {
  const url = getAssignmentUrl('/assignments');

  return POST<{ assignment: AssignmentDetails }>({ url, token, body, signal })
    .then(res => res.assignment)
    .catch((err: FetchErrorWithData) => {
      throw err.errorData || Error(`Failed to create assignment: ${err.message}`);
    });
}

export async function duplicateAssignment(token: string, settings: AssignmentPayload, nuggets: string[]) {
  const newAssignment = await createNewAssignment(token, settings);

  try {
    await addNugget(token, newAssignment.assignmentId, nuggets);
  } catch (error) {
    await deleteAssignmentById(token, newAssignment.assignmentId);
    throw error;
  }

  return newAssignment;
}

export async function duplicateAssignmentGroup(
  token: string,
  assignmentId: string,
  body: DuplicateAssignmentGroupPayload,
  signal: AbortSignal
) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/duplicate`);

  return POST<{ assignment: AssignmentDetails }>({ url, token, body, signal })
    .then(res => res.assignment)
    .catch(err => {
      throw err.errorData || Error(`Failed to duplicate assignment group: ${err.message}`);
    });
}

export function updateAssignment(token: string, assignmentId: string, body: AssignmentPayload) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}`);

  return PUT<{ assignment: AssignmentDetails }>({ url, token, body }).catch((err: FetchErrorWithData) => {
    throw err.errorData || Error(`Failed to update assignment: ${err.message}`);
  });
}

export function getAssignmentById(token: string, assignmentId: string, signal: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}`);

  return GET<{ assignment: AssignmentDetails }>({ url, token, signal })
    .then(res => res.assignment)
    .catch((err: FetchErrorWithData) => {
      throw Error(`Failed to get assignment: ${err.message}`);
    });
}

export function deleteAssignmentById(token: string, assignmentId: string) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}`);

  return DELETE({ url, token }).catch((err: FetchErrorWithData) => {
    throw err.errorData || Error(`Failed to delete assignment: ${err.message}`);
  });
}

export function getRecentAssignments(token: string, opts: Record<string, any>, signal: AbortSignal) {
  const url = getAssignmentUrl('/assignments/recent');
  url.searchParams.set('include', 'summary');
  setUrlSearchParams(url, opts);

  return GET<PaginatedResult<AssignmentsData>>({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to get recent assignments: ${err.message}`);
  });
}

export function addClasses(token: string, assignmentId: string, classIds: string[], signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/groups/classes`);

  return POST<{ groups: AssignmentGroup[] }>({ url, token, signal, body: { classIds } }).catch(err => {
    throw new Error(`Failed to add classes to assignment: ${err.message}`);
  });
}

export function removeAssignmentGroup(token: string, assignmentId: string, assignmentGroupId: string, signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/groups/${assignmentGroupId}`);

  return DELETE({ url, token, signal }).catch(err => {
    throw new Error(`Failed to remove class from assignment: ${err.message}`);
  });
}

export function addStudentGroup(token: string, assignmentId: string, studentIds: string[], signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/groups`);
  const body = {
    group: {
      kind: 'Student',
      studentIds,
    },
  };

  return POST<{ group: AssignmentGroup }>({ url, token, signal, body }).catch(err => {
    throw new Error(`Failed to add student group to assignment: ${err.message}`);
  });
} 