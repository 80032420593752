import {
  CdsStatValue,
  CdsStatValueSize,
  DataRow,
  DataRowItemTypes,
  StatIndicator,
  StatIndicatorSizes,
  StatIndicatorTypes,
  Widget,
} from '@ctek/design-system';
import { AssignmentDetails, MarkbookSummary } from 'century-core/core-apis/assignment/types';
import { DoubleDash } from 'century-core/core-components/DoubleDash/DoubleDash';
import { formatDateAndTime } from 'century-core/core-utils/utils/date/date';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import { FormattedMessage } from 'react-intl';
import { getLateSubmissionInDays, translateLateSubmission } from '../helpers/helpers';
import { AverageScoreIndicatorCell } from './AverageScoreIndicatorCell';
import { DataItem } from './DataItem';

export const QA = 'markbook-stats'

type MarkbookStatsWidgetProps = {
  assignment: AssignmentDetails;
  summary: MarkbookSummary;
};

export const MultipleStudentsStatsWidget = (props: MarkbookStatsWidgetProps) => {
  return (
    <Widget>
      <Widget.Body>
        <Stats {...props} />
      </Widget.Body>
    </Widget>
  );
};

const Stats = (props: MarkbookStatsWidgetProps) => {
  switch (props.assignment.status) {
    case 'DRAFT':
    case 'SCHEDULED':
      return <DraftScheduledStats {...props} />;
    case 'IN_PROGRESS':
      return <InProgressStats {...props} />;
    case 'ENDED':
      return <EndedStats {...props} />;
    case 'CLOSED':
      return <ClosedStats {...props} />;
    default:
      return null;
  }
};

const DraftScheduledStats = ({ assignment }: { assignment: AssignmentDetails }) => {
  const lateSubmissionInDays = getLateSubmissionInDays(assignment);
  const lateSubmissionTranslation = translateLateSubmission(lateSubmissionInDays);

  return (
    <DataRow itemBackgrounds={true}>
      <DataItem
        label={<FormattedMessage id="assignment-status-scheduled-for" defaultMessage="Scheduled for" />}
        value={assignment.schedule?.startAt ? formatDateAndTime(assignment.schedule.startAt) : <DoubleDash />}
        status={DataItemStatusMap[assignment.status]}
        qa={`${QA}-scheduled-for`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-status-due" defaultMessage="Due" />}
        value={assignment.schedule?.dueAt ? formatDateAndTime(assignment.schedule.dueAt) : <DoubleDash />}
        status={DataItemStatusMap[assignment.status]}
        qa={`${QA}-due`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-late-submissions-label" defaultMessage="Late submissions" />}
        value={<FormattedMessage id={lateSubmissionTranslation.messageId} defaultMessage={lateSubmissionTranslation.defaultMessage} />}
        status={DataItemStatusMap[assignment.status]}
        qa={`${QA}-late-submissions`}
      />
    </DataRow>
  );
};

const InProgressStats = ({ assignment, summary }: { assignment: AssignmentDetails; summary: MarkbookSummary }) => {
  const averageScore = getPercentValue(summary.averageScore);
  const lateSubmissionInDays = getLateSubmissionInDays(assignment);
  const lateSubmissionTranslation = translateLateSubmission(lateSubmissionInDays);

  return (
    <DataRow itemBackgrounds={true}>
      <DataItem
        label={<FormattedMessage id="assignment-started" defaultMessage="Started" />}
        value={formatDateAndTime(assignment.schedule?.startAt)}
        status={DataRowItemTypes.ACTIVE}
        qa={`${QA}-started`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-status-due" defaultMessage="Due" />}
        value={formatDateAndTime(assignment.schedule?.dueAt)}
        status={DataRowItemTypes.ACTIVE}
        qa={`${QA}-due`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-late-submissions-label" defaultMessage="Late submissions" />}
        value={<FormattedMessage id={lateSubmissionTranslation.messageId} defaultMessage={lateSubmissionTranslation.defaultMessage} />}
        status={DataRowItemTypes.ACTIVE}
        qa={`${QA}-late-submissions`}
      />
      <CompletedByIndicatorCell completionCount={summary.completion?.count} totalStudents={summary.completion?.total} />
      <AverageScoreIndicatorCell score={averageScore} seconds={summary.timeSpent?.seconds} />
    </DataRow>
  );
};

const EndedStats = ({ assignment, summary }: { assignment: AssignmentDetails; summary: MarkbookSummary }) => {
  const averageScore = getPercentValue(summary.averageScore);

  return (
    <DataRow itemBackgrounds={true}>
      <DataItem
        label={<FormattedMessage id="assignment-status-overdue-since" defaultMessage="Overdue Since" />}
        value={formatDateAndTime(assignment.schedule?.dueAt)}
        status={DataRowItemTypes.ACTIVE}
        qa={`${QA}-overdue-since`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-status-late-submissions-until" defaultMessage="Late submissions until" />}
        value={formatDateAndTime(assignment.schedule?.closeAt)}
        status={DataRowItemTypes.ACTIVE}
        qa={`${QA}-late-submissions-until`}
      />
      <CompletedByIndicatorCell completionCount={summary.completion?.count} totalStudents={summary.completion?.total} />
      <AverageScoreIndicatorCell score={averageScore} seconds={summary.timeSpent?.seconds} />
    </DataRow>
  );
};

const ClosedStats = ({ assignment, summary }: { assignment: AssignmentDetails; summary: MarkbookSummary }) => {
  const averageScore = getPercentValue(summary.averageScore);

  return (
    <DataRow itemBackgrounds={true}>
      <DataItem
        label={<FormattedMessage id="assignment-status-due" defaultMessage="Due" />}
        value={formatDateAndTime(assignment.schedule?.dueAt)}
        status={DataRowItemTypes.INFO}
        qa={`${QA}-due`}
      />
      <DataItem
        label={<FormattedMessage id="assignment-status-completed" defaultMessage="Completed" />}
        value={formatDateAndTime(assignment.schedule?.closeAt)}
        status={DataRowItemTypes.INFO}
        qa={`${QA}-completed`}
      />
      <CompletedByIndicatorCell completionCount={summary.completion?.count} totalStudents={summary.completion?.total} />
      <AverageScoreIndicatorCell score={averageScore} seconds={summary.timeSpent?.seconds} />
    </DataRow>
  );
};

const CompletedByIndicatorCell = ({ completionCount, totalStudents }: { completionCount: number; totalStudents: number }) => {
  return (
    <DataRow.Item status={DataRowItemTypes.INFO} qa={`${QA}-completed-by`}>
      <CdsStatValue size={CdsStatValueSize.MEDIUM}>
        <CdsStatValue.Label>
          <CdsStatValue.Indicator>
            <StatIndicator type={StatIndicatorTypes.COMPLETION} size={StatIndicatorSizes.MEDIUM} score={completionCount} />
          </CdsStatValue.Indicator>
          <FormattedMessage id="assignments-table-header-completion" defaultMessage="Completed by" />
        </CdsStatValue.Label>
        <CdsStatValue.Value>
          {completionCount}
          <CdsStatValue.Total>
            <FormattedMessage
              id="assignment-completion-total-students"
              defaultMessage="of {total} students"
              values={{ total: totalStudents }}
            />
          </CdsStatValue.Total>
        </CdsStatValue.Value>
      </CdsStatValue>
    </DataRow.Item>
  );
};

const DataItemStatusMap = {
  DRAFT: DataRowItemTypes.WARNING,
  SCHEDULED: DataRowItemTypes.DEFAULT,
  IN_PROGRESS: DataRowItemTypes.ACTIVE,
  ENDED: DataRowItemTypes.ACTIVE,
  CLOSED: DataRowItemTypes.INFO,
  ARCHIVED: DataRowItemTypes.DEFAULT,
  NOT_PUBLISHED: DataRowItemTypes.DEFAULT,
} as const;
