import { useState, useEffect, useMemo, ChangeEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';

import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { usePageViewEvent } from 'century-core/core-components/MixpanelUtils';

import { FilterToolbar, BoxSimple, AccessIllustration, Heading, Text } from '@ctek/design-system';
import { withErrorBoundaryPage } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { Spinner } from '../../../core-components/Spinner/Spinner';
import { AssignmentTable } from './AssignmentTable';
import { getAssignmentTableDataAndFilterBarStatus } from 'century-core/core-utils/selectors/assignmentsv1';
import { getMultipleUsersById } from 'century-core/core-apis/accountsV2/users/users';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import StudentDashboardAssignmentsOptions from './StudentDashboardAssignmentsOptions';
import StudentDashboardAssignmentsFilters from './StudentDashboardAssignmentsFilters';
import { useDataLoader } from 'century-core/core-utils/hooks/use-data-loader';
import { AssignmentTableData, getAssignmentsByUserId } from 'century-core/core-apis/learn/assignments/learnAssignments';
import { ActiveStateFilter } from 'century-core/entities/Assignment/Assignment';
import { sortStringData } from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';
import { uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';

export enum Option {
  BARS = 'Show Bars',
  NUMBERS = 'Show Numbers',
  SYMBOLS = 'Show Symbols',
  CELLS = 'Coloured Cells',
}

enum FilterType {
  SUBJECT = 'subject',
  TEACHER = 'teacher',
  SHOW = 'show',
}

export enum ShowFilter {
  ALL = 'ALL',
  ACTIVE = 'active',
  PAST = 'past',
}

// phrase app key prefix
export const prefix = 'dependant-assignments-';

interface Props {
  studentId: string;
  userMode: DashboardUserMode;
}

const StudentDashboardAssignmentsV1 = (props: Props) => {
  const { studentId, userMode } = props;

  const intl = useIntl();

  // Filters
  const [subjectId, setSubjectId] = useState('ALL');
  const [teacherId, setTeacherId] = useState('ALL');
  const [activeStateFilter, setActiveStateFilter] = useState<ActiveStateFilter>('ALL');
  // Options Menu
  const [showBars, setShowBars] = useState<boolean>(true);
  const [showNumbers, setShowNumbers] = useState<boolean>(true);
  const [showSymbols, setShowSymbols] = useState<boolean>(true);
  const [colouredCells, setColouredCells] = useState<boolean>(false);

  const isTeach = userMode === DashboardUserMode.TEACHER;

  usePageViewEvent(
    userMode === DashboardUserMode.GUARDIAN
      ? 'guardian-dependant-assignments'
      : userMode === DashboardUserMode.STUDENT
      ? 'student-dashboard-assignments'
      : userMode === DashboardUserMode.TEACHER
      ? 'teacher-student-dashboard-assignments'
      : ''
  );

  const fetchAssignmentData = useCallback(
    async (token, signal) => {
      const assignments = await getAssignmentsByUserId(studentId, token, signal);
      const allSubjects = assignments.map(item => ({ title: item.subjectName, value: item.subjectId }));
      const uniqueSubjects = uniqBy(allSubjects, 'value');
      const result: {
        assignments: AssignmentTableData[];
        subjects: SelectOption[];
        teacherList: SelectOption[];
      } = {
        assignments,
        subjects: sortStringData(uniqueSubjects, 'title'),
        teacherList: [],
      };

      if (isTeach) {
        const uniqueTeacherIds = Array.from(new Set(assignments.map(a => a.assignedBy)));
        const teacherList = uniqueTeacherIds.length ? await getMultipleUsersById(uniqueTeacherIds, token) : [];
        result.teacherList = [
          ...teacherList.map(teacher => ({
            title: `${teacher.personal?.name?.first.charAt(0).toUpperCase()} ${teacher.personal?.name?.last}`,
            value: teacher._id as string,
          })),
        ];
      }

      return result;
    },
    [isTeach, studentId]
  );

  const { data, loading } = useDataLoader(fetchAssignmentData, true);

  const changeSubject = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSubjectId(value);
    sendFilterChangeMixpanel(FilterType.SUBJECT, value);
  };

  const changeTeacher = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setTeacherId(value);
    const selectedTeacher = data?.teacherList.find((t: SelectOption) => t.value === value);
    if (selectedTeacher) return sendFilterChangeMixpanel(FilterType.TEACHER, selectedTeacher.title || value);
  };

  const changeShowFilter = (type: ShowFilter) => {
    setActiveStateFilter(type);
    sendFilterChangeMixpanel(FilterType.SHOW, type);
  };

  const sendFilterChangeMixpanel = (type: FilterType, value: string) => {
    mixpanel.track(MixpanelEventTypes.StudentDashboardAssignmentsFilterChanged, {
      [MixpanelKeys.StudentId]: studentId,
      [MixpanelKeys.FilterType]: type,
      [MixpanelKeys.SelectedValue]: value,
    });
  };
  // turn on numbers if no other option selected
  useEffect(() => {
    if (!colouredCells && !showNumbers && !showSymbols && !showBars) {
      setShowNumbers(true);
    }
  }, [colouredCells, showSymbols, showBars, showNumbers]);
  const sendOptionChangedMixpanel = (option: Option, isChecked: boolean) => {
    mixpanel.track(MixpanelEventTypes.StudentDashboardAssignmentsOptionChanged, {
      [MixpanelKeys.StudentId]: studentId,
      [MixpanelKeys.OptionChanged]: option,
      [MixpanelKeys.Value]: isChecked,
    });
  };

  const onOptionChange = (option: Option, isChecked: boolean) => {
    sendOptionChangedMixpanel(option, isChecked);
    switch (option) {
      case Option.BARS:
        setShowBars(isChecked);
        break;
      case Option.CELLS:
        setColouredCells(isChecked);
        if (isChecked && showSymbols) {
          setShowSymbols(false);
        }
        break;
      case Option.NUMBERS:
        setShowNumbers(isChecked);
        break;
      case Option.SYMBOLS:
        setShowSymbols(isChecked);
        if (isChecked && colouredCells) {
          setColouredCells(false);
        }
        break;
      default:
        break;
    }
  };

  const assignments = useMemo(() => {
    if (!data?.assignments) return [];

    return getAssignmentTableDataAndFilterBarStatus(data.assignments, activeStateFilter, subjectId, teacherId);
  }, [data, activeStateFilter, subjectId, teacherId]);

  return (
    <>
      {data && (
        <FilterToolbar>
          <StudentDashboardAssignmentsFilters
            subjectId={subjectId}
            subjects={data.subjects ?? []}
            onSubjectChange={changeSubject}
            isTeach={isTeach}
            teacherId={teacherId}
            teacherList={data.teacherList ?? []}
            onChangeTeacher={changeTeacher}
            selectedType={activeStateFilter}
            onTypeChange={changeShowFilter}
          />
          <StudentDashboardAssignmentsOptions
            showBars={showBars}
            showNumbers={showNumbers}
            showSymbols={showSymbols}
            colouredCells={colouredCells}
            onOptionChange={onOptionChange}
          />
        </FilterToolbar>
      )}
      {loading ? (
        <Spinner type="widget" />
      ) : data?.assignments && data.assignments.length ? (
        <AssignmentTable
          assignments={assignments}
          showBars={showBars}
          showNumbers={showNumbers}
          showSymbols={showSymbols}
          colouredCells={colouredCells}
          teacherList={data?.teacherList}
          isTeach={isTeach}
          studentId={studentId}
        />
      ) : (
        <BoxSimple>
          <AccessIllustration illustration="no-courses" />
          <Heading.SectionTitle>
            {intl.formatMessage({ id: 'assignments-tab-no-assignments-title', defaultMessage: 'No Assignments' })}
          </Heading.SectionTitle>
          <Text.Message>
            {userMode === DashboardUserMode.STUDENT
              ? intl.formatMessage({
                  id: 'assignments-results-no-assignments-body',
                  defaultMessage: "Once you've been assigned courses, you'll be able to see your progress here.",
                })
              : intl.formatMessage({
                  id: 'guardian-assignments-results-no-assignments-body',
                  defaultMessage: "Once you have assigned courses to the learner, you'll be able to see their progress here.",
                })}
          </Text.Message>
        </BoxSimple>
      )}
    </>
  );
};

export default withErrorBoundaryPage(StudentDashboardAssignmentsV1);
