import {
  AccessIllustration,
  AccessIllustrationTypes,
  Button,
  ColorEmphasis,
  ColorEmphasisVariant,
  LoadingSpinner,
  retryIcon,
  Widget,
  WidgetEmptyState,
  WidgetError,
} from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';

type MessageObject = {
  id: string;
  message: string;
};

export type EmptyState = {
  isEmpty: boolean;
  content?: MessageObject;
  title?: MessageObject;
  illustrationType?: AccessIllustrationTypes;
};

type ErrorState = {
  error?: Error | undefined;
  customErrorMessage?: MessageObject;
};

type WidgetWrapperProps = {
  children: React.ReactNode;
  emptyState: EmptyState;
  errorState: ErrorState;
  loading?: boolean;
  hasClearFilter?: boolean;
  onClearFilter?: () => void;
  isScrollable?: boolean;
};

const WidgetBodyWrapper = (props: WidgetWrapperProps) => {
  const {
    loading,
    emptyState: { isEmpty, content, title, illustrationType },
    errorState: { error, customErrorMessage },
    children,
    hasClearFilter,
    isScrollable,
    onClearFilter,
  } = props;

  if (loading) {
    return (
      <Widget.Body>
        <LoadingSpinner type="widget" overlay={true} />
      </Widget.Body>
    );
  }

  if (error) {
    return (
      <Widget.Body>
        <WidgetError qa="widget-error">
          <WidgetError.Message qa="error-message">
            <FormattedMessage
              id={customErrorMessage?.id || 'generic-error-message'}
              defaultMessage={customErrorMessage?.message || 'There was an error loading this data'}
            />
          </WidgetError.Message>
          <WidgetError.Controls>
            <Button icon={retryIcon} size="small" variant="secondary" onClick={() => window.location.reload()}>
              <FormattedMessage id="error-reload-button-label" defaultMessage="Reload" />
            </Button>
          </WidgetError.Controls>
        </WidgetError>
      </Widget.Body>
    );
  }

  if (isEmpty) {
    return (
      <WidgetEmptyState hasPanel={true} qa="widget-empty-state">
        {illustrationType && <AccessIllustration illustration={illustrationType} qa="widget-empty-state-illustration" />}
        <WidgetEmptyState.Title>
          <FormattedMessage id={title?.id || 'generic-no-data'} defaultMessage={title?.message || 'No data'} />
        </WidgetEmptyState.Title>
        <WidgetEmptyState.Message>
          <FormattedMessage
            id={content?.id || 'generic-no-data-message'}
            defaultMessage={content?.message || 'There is no data to display'}
          />
        </WidgetEmptyState.Message>
        <br />
        {hasClearFilter && (
          <Button variant="muted" size="small" onClick={onClearFilter}>
            <ColorEmphasis variant={ColorEmphasisVariant.INFO}>
              <FormattedMessage id="clear-filters-button-label" defaultMessage="Clear Filters" />
            </ColorEmphasis>
          </Button>
        )}
      </WidgetEmptyState>
    );
  }

  return <Widget.Body isScrollable={isScrollable}>{children}</Widget.Body>;
};

export default WidgetBodyWrapper;
