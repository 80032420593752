import { CdsStatValue, CdsStatValueSize, DataRow, DataRowItemTypes, StatIndicator, StatIndicatorSizes, StatIndicatorTypes } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { QA } from './MultipleStudentsStatsWidget';

export const AverageScoreIndicatorCell = ({ score, seconds }: { score: number; seconds?: number }) => {
  if (!seconds) {
    return null;
  }
  return (
    <DataRow.Item status={getStatusColourFromScore(score)} qa={`${QA}-average-score`}>
      <CdsStatValue size={CdsStatValueSize.MEDIUM}>
        <CdsStatValue.Label>
          <CdsStatValue.Indicator>
            <StatIndicator type={StatIndicatorTypes.SCORE} size={StatIndicatorSizes.MEDIUM} score={score} />
          </CdsStatValue.Indicator>
          <FormattedMessage id="assignments-table-header-averageLastScore" defaultMessage="Avg. Score" />
        </CdsStatValue.Label>
        <CdsStatValue.Value>
          {score}
          <CdsStatValue.Unit>%</CdsStatValue.Unit>
        </CdsStatValue.Value>
      </CdsStatValue>
    </DataRow.Item>
  );
};

const getStatusColourFromScore = (score: number): DataRowItemTypes => {
  switch (true) {
    case score <= 39:
      return DataRowItemTypes.PASSIVE;
    case score <= 69:
      return DataRowItemTypes.PASSIVE;
    case score <= 100:
      return DataRowItemTypes.SUCCESS;
    default:
      return DataRowItemTypes.DEFAULT;
  }
};