import jwt_decode from 'jwt-decode';
import { Auth } from 'century-core/entities/Auth/Auth';

/**
 * returns parsed raw jwt token
 */
export function getTokenData<T>(token: string = ''): T {
  if (!token || token === '') {
    throw Error('Invalid token string');
  }

  try {
    return jwt_decode<T>(token);
  } catch (e) {
    throw Error(`Unable to decode token: "${token}"`);
  }
}

/**
 * Decides if a token is still valid.
 *
 * We use a safety margin to compensate for the error in timeToExpireAccessToken
 * and the time it takes to use the token. A token valid for only 1 sec would be
 * useless.
 */
export function isAccessTokenValid(auth: Auth): boolean {
  const time = timeToExpireAccessToken(auth);
  // We need some time left in the token to be able to use it.
  // We renew it if the time left is < 5 min
  return !!time && time > 300000;
}

/**
 * Calculates the time, in ms, it takes for the token to expire, using the
 * browser's timestamp for when that token was issued. There will be some error
 * due to the time it takes to complete the request and client side processing.
 */
export function timeToExpireAccessToken(auth: Auth): number | null {
  const ttl = tokenTtl(auth);
  // we can't trust the token iat as the user clock and servers may differ
  const iat = auth.accessTokenData!.iat * 1000; // iat is in s, need to convert to ms
  const now = Date.now();

  if (!ttl || !iat) {
    return null;
  }

  const expireDate = iat + ttl;
  return expireDate > now ? expireDate - now : null;
}

/**
 * Schedules the renewal of access tokens
 */
export function scheduleRenewToken(auth: Auth, fn: () => void, renewTokenTimer: NodeJS.Timeout) {
  const timeToExpire = timeToExpireAccessToken(auth) || 0;
  const tokenDuration = tokenTtl(auth) || 0;
  const idealRenewTtl = tokenDuration / 3;
  const delay = timeToExpire > idealRenewTtl ? timeToExpire - idealRenewTtl : 0;

  delayRenewToken(fn, renewTokenTimer, delay);
}

/**
 * Delays the renewal of access tokens
 */
export function delayRenewToken(fn: () => void, renewTokenTimer: NodeJS.Timeout, delay: number) {
  if (renewTokenTimer) {
    clearTimeout(renewTokenTimer);
  }

  renewTokenTimer = setTimeout(fn, delay);
}

/**
 * Calculates the token duration in ms.
 */
export function tokenTtl(auth: Auth): number | null {
  const data = auth.accessTokenData;

  if (!data || !data.exp || !data.iat) {
    return null;
  }

  return (data.exp - data.iat) * 1000;
}

/**
 * Gets token sub.
 *
 * If the token is undefined, it throws an error.
 */
export function getTokenSub(auth: Auth): string {
  const tokenData: Ctek.JWTData | undefined = auth.accessTokenData;

  if (!tokenData) {
    throw new Error('Unable to retrieve sub from empty Token');
  }

  return tokenData.sub;
}

/**
 * Returns if the user is a test user or not
 */
export const getIsTestUser = (auth: Auth): boolean => {
  return auth.accessTokenData?.context.isTest ?? true;
}

/**
 * Gets login session Id from context according to token version.
 *
 * If the token or the loginSession are undefined, it returns an empty string.
 */
export const getTokenLoginSessionId = (auth: Auth): string => {
  return auth.accessTokenData?.context.loginSessionId || '';
};

export function sendMessageToServiceWorker(message: string): void {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message);
  }
}

export function isAccessTokenStillValid(accessToken: string): boolean {
  const tokenData = getTokenData<Ctek.JWTData>(accessToken);
  const ttl = tokenTtl({ accessTokenData: tokenData });
  const iat = tokenData.iat * 1000; // iat is in s, need to convert to ms
  const now = Date.now();

  if (!ttl || !iat) {
    return false;
  }
  const expireDate = iat + ttl;
  const time = expireDate > now ? expireDate - now : null;
  // We need some time left in the token to be able to use it.
  // We renew it if the time left is < 3 min
  return !!time && time > 180000;
}

/**
 * Checks the user is logged, by in-memory auth that comes from AuthContext. Local storage gets read there.
 *
 * The check for `|| !!auth.refreshToken` is not really necessary, just for really edge cases. AccessToken in
 * memory should be valid as, if the one from local storage is not valid, it's renew automatically. The
 * access token in memory should never expire as the scheduleRenewToken function should act before that.
 */
export function isLoggedIn(auth: Auth): boolean {
  const isTokenValid = auth?.accessTokenData && isAccessTokenValid(auth);
  return isTokenValid || !!auth.refreshToken;
}
