import { TimeDuration } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentsV3/common/TimeDuration';
import { PaginationWithMixpanel } from 'century-core/core-components/PaginationWithMixpanel/PaginationWithMixpanel';
import { TableHeaderWithSorting } from 'century-core/core-components/TableHeaderWithSorting/TableHeaderWithSorting';
import { ScorePercentage, TableCellClickable, TableResponsive, TableSimple, Widget } from '@ctek/design-system';
import { ExtendedPaginatedResult } from 'century-core/core-apis/assignment/reports';
import { FormattedMessage } from 'react-intl';
import WidgetBodyWrapper from 'century-core/core-components/WidgetBodyWrapper/WidgetBodyWrapper';
import { usePageViewEvent } from 'century-core/core-components/MixpanelUtils';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { UsePaginationDataLoaderReturn } from 'century-core/core-utils/hooks/use-paginated-data-loader';
import { AssignmentDetails } from 'century-core/core-apis/assignment/types';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import {
  QLATooltip,
  Row,
} from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentsV3/AssignmentQLA/AssignmentQLA';
import mixpanel from 'mixpanel-browser';

type IndividualStudentTableProps = {
  paginatedData: UsePaginationDataLoaderReturn<ExtendedPaginatedResult>;
  assignment: AssignmentDetails;
  qlaLabels: Row[];
  openQLA: (studentId: string, nuggetId: string) => void;
  hasQLA?: boolean;
};

export const IndividualStudentTable = (props: IndividualStudentTableProps) => {
  const { assignment, paginatedData, qlaLabels, openQLA, hasQLA = true } = props;
  const { assignmentId, status } = assignment;
  const { data: reports, loading, error } = paginatedData;
  usePageViewEvent('teacher-dashboard-individual-student-markbook');

  const trackPageNavigation = (navigatedValue: string) => {
    mixpanel.track(MixpanelEventTypes.TeacherDashboardAssignmentsTableNavigationChanged, {
      [MixpanelKeys.Navigate]: navigatedValue,
      [MixpanelKeys.AssignmentId]: assignmentId,
      [MixpanelKeys.AssignmentStatus]: status,
    });
  };

  return (
    <Widget isLoading={loading}>
      <Widget.Header>
        <Widget.Title>
          <FormattedMessage id="teacher-dashboard-assignment-markbook-tab" defaultMessage="Assignment Markbook" />
        </Widget.Title>
      </Widget.Header>
      <WidgetBodyWrapper
        emptyState={{
          title: { id: 'assignment-markbook-table-no-data-title', message: 'No data available yet' },
          content: {
            id: 'assignment-markbook-individual-table-no-data-message',
            message: 'Assignment markbook data will appear here once the student has started working on it.',
          },
          isEmpty: !reports?.data?.length || assignment.status === 'DRAFT',
        }}
        errorState={{ error }}
      >
        <TableResponsive qa="individual-markbook-table">
          <TableSimple striped={true} hover={true}>
            <TableHeaderWithSorting headersData={generateHeaders()} isSortingEnabled={false} {...paginatedData} />
            <tbody data-testid="individual-markbook-table-body">
              {reports?.data[0]?.summary?.nuggets.map(nugget => {
                const userId = reports?.data[0].user.userId;
                const nuggetId = nugget.nuggetId;
                const userScoreLabel = (qlaLabels.find(({ id }) => id === userId)?.[nuggetId] as QLATooltip) ?? null;

                return (
                  <tr key={nuggetId}>
                    <th>
                      {hasQLA && nugget.attemptCount > 0 && typeof userScoreLabel?.score === 'number' ? (
                        <TableCellClickable
                          qa={`${userId}-${nuggetId}`}
                          label={userScoreLabel?.label}
                          onClick={() => openQLA(userId, nuggetId)}
                        >
                          {nugget.name}
                        </TableCellClickable>
                      ) : (
                        nugget.name
                      )}
                    </th>
                    <td>{nugget.studyTime?.seconds ? <ScorePercentage percentage={getPercentValue(nugget.score)} hasBar={true} /> : ''}</td>
                    <td>{nugget.attemptCount > 0 && nugget.attemptCount}</td>
                    <td>{<TimeDuration timeSpentSeconds={nugget.studyTime.seconds} />} </td>
                  </tr>
                );
              })}
            </tbody>
          </TableSimple>
        </TableResponsive>
        <PaginationWithMixpanel trackPageNavigation={trackPageNavigation} {...paginatedData} />
      </WidgetBodyWrapper>
    </Widget>
  );
};

const columnNamesMap = new Map([
  ['nugget', 'NUGGET'],
  ['score', 'SCORE'],
  ['attempts', 'ATTEMPTS'],
  ['studyTime', 'STUDY TIME'],
]);

const generateHeaders = () => {
  return ['nugget', 'score', 'attempts', 'studyTime'].map(col => ({
    col,
    columnMessageId: `assignment-individual-markbook-header-${col}`,
    columnDefaultMessage: columnNamesMap.get(col) || col,
  }));
};
