import { ModalBox } from '@ctek/design-system';
import QLA from '../../../common/QuestionLevelAnalysis/QLAWrapper';
import { QLANavigationInformationProvider } from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLANavigationInformationProvider';
import { useIntl } from 'react-intl';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { CompletionNugget } from 'century-core/core-apis/learn/aggregations/aggregations';

export type QLATooltip = { score: number | null; label: string };
export type Row = {
  [index: string]: string | QLATooltip;
};
export type QLANugget = {
  id: string;
  name: string;
  students: string[];
};

export type QLAData = {
  users: Row[];
  nuggets: QLANugget[];
  nuggetCompletionData: CompletionNugget[];
};

type selectedQLA = {
  nuggetQLA: string;
  studentQLA: string;
};

interface AssignmentMarkbookQLAProps {
  qlaData: QLAData;
  selectedQLA: selectedQLA;
  showQLA: boolean;
  setShowQLA: (show: boolean) => void;
  qa: string;
}

export default function AssignmentQLA(props: AssignmentMarkbookQLAProps) {
  const { qlaData, selectedQLA, qa, showQLA, setShowQLA } = props;

  const intl = useIntl();
  const { deviceType } = useDeviceInfo();

  if (!qlaData.users || !qlaData.nuggets) {
    return null;
  }

  return (
    <QLANavigationInformationProvider
      students={qlaData.users}
      nuggets={qlaData.nuggets}
      shouldDisplayNugget={(nugget: QLANugget, student: any) => nugget.students.includes(student.id)}
      getNuggetProp={getNuggetPropQlaNav}
      getStudentProp={getStudentPropQlaNav}
    >
      <ModalBox
        dismissLabel={intl.formatMessage({ id: 'modal-dismiss-button', defaultMessage: 'Dismiss' })}
        floatingDismiss={deviceType === 'phone' ? false : true}
        modalActive={showQLA}
        onExit={() => setShowQLA(false)}
        size={'xxlarge'}
        qa={qa}
      >
        <ModalBox.Body>
          <QLA userId={selectedQLA.studentQLA} nuggetId={selectedQLA.nuggetQLA} expandFirstAttempt={true} />
        </ModalBox.Body>
      </ModalBox>
    </QLANavigationInformationProvider>
  );
}

const getNuggetPropQlaNav = (nugget: QLANugget, nuggetKey: string) => nugget[nuggetKey];

const getStudentPropQlaNav = (user: Row, userKey: string) => {
  if (userKey === 'name') {
    return user.student as string;
  }
  return user[userKey] as string;
};
