import { TableHeaderSortable, TableHeaderSortableSize, TextClamp } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { getSortingOrder, SortSettings, updateSortSettings } from 'century-core/core-utils/utils/tableSorting/tableSortingV2';
import { useState } from 'react';

export interface TableHeaderWithSortingProps {
  loading: boolean;
  headersData: { col: string; columnMessageId: string; columnDefaultMessage: string; size?: TableHeaderSortableSize }[];
  isSortingEnabled: boolean;
  defaultSortSettings: SortSettings;
  handleSortingAndTracking?: (col: string, sortingOrder: 'asc' | 'desc') => void;
  sortableColumns?: string[];
}

export const TableHeaderWithSorting = (props: TableHeaderWithSortingProps) => {
  const { loading, defaultSortSettings, headersData, sortableColumns, isSortingEnabled, handleSortingAndTracking } = props;
  const [sortSettings, setSortSettings] = useState<Partial<SortSettings<string>>>(defaultSortSettings);

  const handleSortColumn = (col: string) => {
    if (!isSortingEnabled) return;
    const settings = updateSortSettings(col, 'string', sortSettings);
    setSortSettings(settings);
    const sortingOrder = getSortingOrder(col, settings);
    handleSortingAndTracking?.(col, sortingOrder);
  };

  return (
    <thead style={{ opacity: loading ? '0.5' : '1', transition: 'opacity 0.5s ease-in-out' }}>
      <tr>
        {headersData.map(({ col, columnMessageId, columnDefaultMessage, size }) => {
          const sortingOrder = getSortingOrder(col, sortSettings);

          return (
            <th data-testid={columnMessageId} scope="col" key={col} title={columnDefaultMessage}>
              {isSortingEnabled && (!sortableColumns || sortableColumns?.includes(col)) ? (
                <TableHeaderSortable
                  sortDirection={sortingOrder}
                  title={col}
                  ariaLabel={col}
                  onClick={() => handleSortColumn(col)}
                  size={size}
                >
                  <FormattedMessage id={columnMessageId} defaultMessage={columnDefaultMessage} />
                </TableHeaderSortable>
              ) : (
                <TextClamp isUppercase={!size}>
                  <FormattedMessage id={columnMessageId} defaultMessage={columnDefaultMessage} />
                </TextClamp>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
