import { Breadcrumbs, BreadcrumbItem, PageButtonsItem, PageControls, PageControlsItem } from './AppHeaderContext';
import { compareArrays } from 'century-core/core-utils/utils/utils';

export interface AppHeaderReducerState {
  breadcrumbs: Breadcrumbs;
  pageControls: PageControls;
  pagePrimaryButtons: { items: PageButtonsItem[] };
  pageDefaultButtons: { items: PageButtonsItem[] };
}

export interface AppHeaderAction {
  type: AppHeaderActions;
  breadcrumb?: BreadcrumbItem;
  control?: PageControlsItem;
  primaryButton?: PageButtonsItem;
  defaultButton?: PageButtonsItem;
  paths?: string[];
}

export const emptyAppHeaderState = {
  breadcrumbs: { items: [] },
  pageControls: { items: [] },
  pagePrimaryButtons: { items: [] },
  pageDefaultButtons: { items: [] },
};

const removeQueryParameters = (url: string) => url.split('?')[0];
const compareBasePath = (path1: string, path2: string) => removeQueryParameters(path1) === removeQueryParameters(path2);

const addBreadcrumb = (state: AppHeaderReducerState, newBreadcrumb: BreadcrumbItem) => {
  const { breadcrumbs } = state;
  const { path, title } = newBreadcrumb;
  const existingBreadcrumbIndex = breadcrumbs.items.findIndex(item => compareBasePath(item.path, path));
  let newBreadcrumbData;
  if (existingBreadcrumbIndex < 0) {
    newBreadcrumbData = {
      ...breadcrumbs,
      items: [...breadcrumbs.items, newBreadcrumb],
    };
  }
  if (existingBreadcrumbIndex >= 0) {
    const existingBreadcrumb = breadcrumbs.items[existingBreadcrumbIndex];
    if ((title && existingBreadcrumb.title !== title) || (path && existingBreadcrumb.path !== path)) {
      breadcrumbs.items[existingBreadcrumbIndex] = newBreadcrumb;
      // ensure this is updated
      newBreadcrumbData = {
        ...breadcrumbs,
        items: [...breadcrumbs.items],
      };
    }
  }
  if (!newBreadcrumbData) {
    return state;
  }
  return {
    ...state,
    breadcrumbs: newBreadcrumbData,
  };
};

const removeBreadcrumb = (state: AppHeaderReducerState, breadcrumbToRemove: BreadcrumbItem) => {
  const { breadcrumbs } = state;
  const existingBreadcrumbIndex = breadcrumbs.items.findIndex(
    item => compareBasePath(item.path, breadcrumbToRemove.path) && item.title === breadcrumbToRemove.title
  );
  if (existingBreadcrumbIndex >= 0) {
    breadcrumbs.items.splice(existingBreadcrumbIndex, 1);
    const newBreadcrumbData = {
      ...breadcrumbs,
      items: [...breadcrumbs.items],
    };
    return {
      ...state,
      breadcrumbs: newBreadcrumbData,
    };
  }
  return state;
};

const clearBreadcrumbs = (state: AppHeaderReducerState) => {
  const emptyBreadcrumbs = { items: [] };
  return {
    ...state,
    breadcrumbs: emptyBreadcrumbs,
  };
};

const addPagePrimaryButton = (state: AppHeaderReducerState, newPrimaryButton: PageButtonsItem) => {
  const itemsAux: PageButtonsItem[] = state.pagePrimaryButtons.items;
  const alreadyExistingItemIndex = itemsAux.findIndex((item: PageButtonsItem) => compareArrays(item.paths, newPrimaryButton.paths));

  if (alreadyExistingItemIndex > -1) {
    itemsAux.splice(alreadyExistingItemIndex, 1);
  }
  const newPrimaryButtonData = {
    items: [...itemsAux, newPrimaryButton],
  };
  return {
    ...state,
    pagePrimaryButtons: newPrimaryButtonData,
  };
};

const removePagePrimaryButton = (state: AppHeaderReducerState, removePaths: string[]) => {
  const itemsWithRemovedElement = state.pagePrimaryButtons.items;
  const indexToRemove = itemsWithRemovedElement.findIndex((item: PageButtonsItem) => compareArrays(item.paths, removePaths));

  if (indexToRemove > -1) {
    itemsWithRemovedElement.splice(indexToRemove, 1);
    const newPrimaryButton = {
      items: [...itemsWithRemovedElement],
    };
    return {
      ...state,
      primaryButton: newPrimaryButton,
    };
  }
  return state;
};

const addPageDefaultButton = (state: AppHeaderReducerState, newDefaultButton: PageButtonsItem) => {
  const itemsAux: PageButtonsItem[] = state.pageDefaultButtons.items;
  const alreadyExistingItemIndex = itemsAux.findIndex((item: PageButtonsItem) => compareArrays(item.paths, newDefaultButton.paths));

  if (alreadyExistingItemIndex > -1) {
    itemsAux.splice(alreadyExistingItemIndex, 1);
  }
  const newDefaultButtonData = {
    items: [...itemsAux, newDefaultButton],
  };
  return {
    ...state,
    pageDefaultButtons: newDefaultButtonData,
  };
};

const removePageDefaultButton = (state: AppHeaderReducerState, removePaths: string[]) => {
  const itemsWithRemovedElement = state.pageDefaultButtons.items;
  const indexToRemove = itemsWithRemovedElement.findIndex((item: PageButtonsItem) => compareArrays(item.paths, removePaths));

  if (indexToRemove > -1) {
    itemsWithRemovedElement.splice(indexToRemove, 1);
    const newDefaultButton = {
      items: [...itemsWithRemovedElement],
    };
    return {
      ...state,
      defaultButton: newDefaultButton,
    };
  }
  return state;
};

const addPageControls = (state: AppHeaderReducerState, newControls: PageControlsItem) => {
  const itemsAux: PageControlsItem[] = state.pageControls.items;
  const alreadyExistingItemIndex = itemsAux.findIndex((item: PageControlsItem) => compareArrays(item.paths, newControls.paths));

  if (alreadyExistingItemIndex > -1) {
    itemsAux.splice(alreadyExistingItemIndex, 1);
  }

  const newPageControls = { items: itemsAux.concat([newControls]) };
  return { ...state, pageControls: newPageControls };
};

const removePageControls = (state: AppHeaderReducerState, removePaths: string[]) => {
  const itemsWithRemovedElement = state.pageControls.items;
  const filteredItems = itemsWithRemovedElement.filter((item: PageControlsItem) => !compareArrays(item.paths, removePaths));

  if (filteredItems.length !== itemsWithRemovedElement.length) {
    const newControls = {
      items: filteredItems,
    };
    return {
      ...state,
      pageControls: newControls,
    };
  }
  return state;
};

export enum AppHeaderActions {
  ADD_BREADCRUMB,
  REMOVE_BREADCRUMB,
  CLEAR_BREADCRUMBS,
  ADD_PAGE_CONTROLS,
  REMOVE_PAGE_CONTROLS,
  ADD_PAGE_PRIMARY_BUTTON,
  REMOVE_PAGE_PRIMARY_BUTTON,
  ADD_PAGE_DEFAULT_BUTTON,
  REMOVE_PAGE_DEFAULT_BUTTON,
}

export const appHeaderInitialiser = (state: AppHeaderReducerState) => ({
  ...state,
});

export const appHeaderReducer = (state: AppHeaderReducerState, action: AppHeaderAction): AppHeaderReducerState => {
  switch (action.type) {
    case AppHeaderActions.ADD_BREADCRUMB:
      return addBreadcrumb(state, action.breadcrumb!);
    case AppHeaderActions.REMOVE_BREADCRUMB:
      return removeBreadcrumb(state, action.breadcrumb!);
    case AppHeaderActions.CLEAR_BREADCRUMBS:
      return clearBreadcrumbs(state);
    case AppHeaderActions.ADD_PAGE_CONTROLS:
      return addPageControls(state, action.control!);
    case AppHeaderActions.REMOVE_PAGE_CONTROLS:
      return removePageControls(state, action.paths!);
    case AppHeaderActions.ADD_PAGE_PRIMARY_BUTTON:
      return addPagePrimaryButton(state, action.primaryButton!);
    case AppHeaderActions.REMOVE_PAGE_PRIMARY_BUTTON:
      return removePagePrimaryButton(state, action.paths!);
    case AppHeaderActions.ADD_PAGE_DEFAULT_BUTTON:
      return addPageDefaultButton(state, action.defaultButton!);
    case AppHeaderActions.REMOVE_PAGE_DEFAULT_BUTTON:
      return removePageDefaultButton(state, action.paths!);
    default:
      return state;
  }
};
