import { useCallback, useEffect, useMemo } from 'react';
import { processQLAData } from './processAssignmentQLA';
import { useDataLoader } from 'century-core/core-utils/hooks/use-data-loader';
import { useIntl } from 'react-intl';
import { ExtendedPaginatedResult } from 'century-core/core-apis/assignment/reports';

export const useAssignmentQLA = (reports: ExtendedPaginatedResult | null) => {
  const intl = useIntl();

  const getQLAData = useCallback(async token => reports && (await processQLAData(token, reports, intl)), [reports, intl]);
  const { data, load: loadQLAData } = useDataLoader(getQLAData);

  useEffect(() => {
    if (reports && reports?.data.length > 0) {
      loadQLAData();
    }
  }, [reports, loadQLAData]);

  return useMemo(() => data ?? { users: [], nuggets: [], nuggetCompletionData: [] }, [data]);
};
