import Internationalize from 'components/Internationalization/Internationalize';
import StreaksWidget from './century-core/features-learner/Streaks/StreaksWidget';
import * as React from 'react';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/utils';
import { PolymerMatchingQuestionTypeReadonly } from './century-core/core-components/DraggableQuestionTypes/Matching/MatchingQuestionTypeReadonly';
import { PolymerLabellingQuestionTypeReadonly } from './century-core/core-components/DraggableQuestionTypes/Labelling/LabellingQuestionTypeReadonly';
import LeadershipStudentView from 'century-core/features-leadership/LeadershipStudentView';
import DocumentDownlodButton from 'century-core/core-components/DocumentDownloadButton/DocumentDownloadButton';
import FilteredDocsDownloader from 'century-core/core-components/DocumentDownloadButton/CombinedDocumentDownloadButton';
import { createApolloClientForPolymer } from 'createApolloClient';
import PolymerToReactAuthProvider from 'century-core/core-auth/hooks/PolymerToReactAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

export interface LocaleAndOrgType {
  locale: string;
  orgType: string;
}

const simpleContextWrapper = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => {
    const auth = parseAuthFromLocalstorage();
    if (!auth) {
      return null;
    }

    return (
      <PolymerToReactAuthProvider>
        <Internationalize>
          <Component {...props} />
        </Internationalize>
      </PolymerToReactAuthProvider>
    );
  };
};

const advancedContextWrapper =
  <P extends object>(Component: React.ComponentType<P>) =>
  ({ routeBaseName, ...restProps }: any) => {
    const client = createApolloClientForPolymer();
    return (
      <PolymerToReactAuthProvider>
        <Internationalize>
          <BrowserRouter basename={routeBaseName}>
            <ApolloProvider client={client}>
              <Component {...restProps} />
            </ApolloProvider>
          </BrowserRouter>
        </Internationalize>
      </PolymerToReactAuthProvider>
    );
  };

// TODO check whether we are still using this.
const PolymerAvailableReactComponents = {
  StreaksWidget: simpleContextWrapper(StreaksWidget),
  MatchingQuestionTypeReadonly: simpleContextWrapper(PolymerMatchingQuestionTypeReadonly),
  LabellingQuestionTypeReadonly: simpleContextWrapper(PolymerLabellingQuestionTypeReadonly),
  StudentDashboard: advancedContextWrapper(LeadershipStudentView), // uses advance context wrapper for graphql, auth, etc
  DocumentDownloadButton: simpleContextWrapper(DocumentDownlodButton),
  NuggetDocxDownloadButton: simpleContextWrapper(FilteredDocsDownloader),
};

export default PolymerAvailableReactComponents;
