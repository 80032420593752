import { FetchErrorWithData } from 'century-core/core-apis/fetch';
import { GET, POST, DELETE } from 'century-core/core-apis/utils';
import { getAssignmentUrl } from './assignmentV1';
import { AssignmentNugget } from './types';

export type AssignmentNuggetsResponse = {
  count: number;
  data: AssignmentNugget[];
};

export function getAssignmentNuggets(token: string, assignmentId: string, signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/nuggets`);

  return GET<AssignmentNuggetsResponse>({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to get assignment nuggets: ${err.message}`);
  });
}

export function addNugget(token: string, assignmentId: string, nuggetIds: string[], signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/nuggets`);

  return POST({ url, token, body: { nuggetIds }, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to add nugget: ${err.message}`);
  });
}
export function deleteNugget(token: string, assignmentId: string, nuggetId: string, signal?: AbortSignal) {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/nuggets/${nuggetId}`);

  return DELETE({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to delete nugget: ${err.message}`);
  });
}
