import { z } from 'zod';

export const SignInEnum = z.enum(['email', 'username', 'sso']);
export type SignInOption = z.infer<typeof SignInEnum>;
export type YesNoOption = 'yes' | 'no' | '';
export type SelectOptions = readonly { value: string; messageId: string; defaultMessage: string }[];

export const SIGN_IN_OPTIONS = [
  { value: SignInEnum.Enum.email, messageId: 'user-email-title', defaultMessage: 'Email' },
  { value: SignInEnum.Enum.username, messageId: 'user-username-title', defaultMessage: 'Username' },
  { value: SignInEnum.Enum.sso, messageId: 'sso-option', defaultMessage: 'Google or MS365 SSO' },
] as const;

export const GENDER_OPTIONS = [
  { value: '', messageId: 'empty-option', defaultMessage: '--' },
  { value: 'female', messageId: 'filters-gender-female', defaultMessage: 'Female' },
  { value: 'male', messageId: 'filters-gender-male', defaultMessage: 'Male' },
  { value: 'unknown', messageId: 'filters-gender-not-specified', defaultMessage: 'Not Specified' },
] as const;

export const YES_NO_OPTIONS = [
  { value: '', messageId: 'empty-option', defaultMessage: '--' },
  { value: 'false', messageId: 'organisation-false-field', defaultMessage: 'No' },
  { value: 'true', messageId: 'organisation-true-field', defaultMessage: 'Yes' },
] as const;

export const translateYesOrNo = (value: string | boolean = '') => YES_NO_OPTIONS.find(option => option.value === String(value));
export const translateGender = (value: string = '') => GENDER_OPTIONS.find(option => option.value === value) || GENDER_OPTIONS[GENDER_OPTIONS.length - 1];
