import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { retrieveAvailableMoods } from './api';
import { DateTime } from 'luxon';
import { trackMoodSelected } from './mixpanel/elaMoodMixpanel';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';
import { useLocalStorage } from 'century-core/core-utils/hooks/useLocalStorage';
import { remove as removeLocalStorageItem } from 'century-core/core-utils/utils/localStorage/localStorage';

export type ElaArea = 'footer' | 'study-session' | 'main-menu-mobile';

export const ELA_STORAGE_KEY = 'ctek.ela';

export interface ELAMoodData {
  availableMoods: Ctek.ElaMood[] | null;
  currentMood: Ctek.ElaMood | null;
  setMood: (id: string, elaArea: ElaArea) => Promise<void>;
}

export const ELAMoodContext = createContext(null as unknown as ELAMoodData);
export const useELAMoodContext = () => React.useContext(ELAMoodContext);

export const clearElaMood = () => {
  removeLocalStorageItem(ELA_STORAGE_KEY);
};

export const ELAMoodProvider = (props: { children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const { formatMessage } = useIntl();
  const [storedElaData, setStoredElaData] = useLocalStorage(ELA_STORAGE_KEY, {});
  const [currentMood, setCurrentMood] = useState<Ctek.ElaMood | null>(null);
  const [availableMoods, setAvailableMoods] = useState<Ctek.ElaMood[]>([]);

  useEffect(() => {
    let isActive = true;
    if (availableMoods.length > 0 || !accessToken) {
      return;
    }

    const fetchMoods = async () => {
      const [fetchedMoods, fetchedCurrentMood] = await Promise.all([retrieveAvailableMoods(accessToken), storedElaData.currentMood]);
      const mood = fetchedMoods.find(item => fetchedCurrentMood?.id === item.id) || fetchedMoods[0];
      if (isActive) {
        setCurrentMood(mood);
        setAvailableMoods(fetchedMoods);
      }
    };

    fetchMoods();
    return () => {
      isActive = false;
    };
  }, [accessToken, availableMoods.length, storedElaData.currentMood]);

  const setMood = async (id: string, elaArea: ElaArea) => {
    if (availableMoods.length > 0) {
      const newMood = availableMoods.find(item => id === item.id) || null;
      if (!newMood) {
        return;
      }

      setCurrentMood(newMood);
      trackMoodSelected(elaArea, formatMessage({ id: newMood.value, defaultMessage: newMood.id }), newMood.id);
      setStoredElaData({ ttl: DateTime.now().endOf('day'), currentMood: newMood });
    }
  };

  return <ELAMoodContext.Provider value={{ currentMood, availableMoods, setMood }}>{props.children}</ELAMoodContext.Provider>;
};
