import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ColorEmphasis,
  ColorEmphasisVariant,
  Widget,
  TableResponsive,
  TableSimple,
  TableHeaderSortable,
  TableHeaderSortableSortDirection,
  ScorePercentage,
  PageContentResponsive,
  CircularStatusIndicator,
  Tooltipped,
  TableCellLink,
} from '@ctek/design-system';
import {
  getSortingOrder,
  updateSortSettings,
  SortingType,
  SortSettings,
  sortTableData,
} from 'century-core/core-utils/utils/tableSorting/tableSortingV2';
import { DurationMessage } from '../../../core-components/DurationMessage/DurationMessage';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { SubjectName } from 'century-core/core-components/Subject/SubjectLabel';
import { AssignmentTableData } from 'century-core/core-apis/learn/assignments/learnAssignments';
import { formatDateAndTime } from 'century-core/core-utils/utils/date/date';
import mixpanel from 'mixpanel-browser';

export enum AssignmentStatus {
  ACTIVE = 'active',
  PAST = 'past',
  PLANNED = 'planned',
}

type TableLabels = 'name' | 'subject' | 'assignmentType' | 'started' | 'deadline' | 'completed' | 'completedOn' | 'score' | 'timeSpent';

interface AssignmentTableProps {
  assignments: AssignmentTableData[];
  teacherList: SelectOption[];
  isTeach: boolean;
  showBars: boolean;
  showNumbers: boolean;
  showSymbols: boolean;
  colouredCells: boolean;
  studentId: string;
}

export const AssignmentTable = (tableProps: AssignmentTableProps) => {
  const [assignments, setAssignments] = useState<AssignmentTableData[]>([]);
  const [sortSettings, setSortSettings] = useState({ name: { order: 'ASCENDING', type: 'string' } } as SortSettings);
  const intl = useIntl();
  const routeParams = useParams();
  const match = useRouteMatch();

  const headers = [
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-name" defaultMessage="Assignment Name" />,
      propName: 'name',
      propType: 'string',
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-subject" defaultMessage="Subject" />,
      propName: 'subject',
      propType: 'string',
      customGetter: (a: AssignmentTableData) => a.subjectName,
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-content" defaultMessage="Content" />,
      propName: 'assignmentType',
      propType: 'number',
      customGetter: (a: AssignmentTableData) => (a.assignmentType === 'essay' ? 0 : a.totalNuggets),
    },
    // only show if teach view mode
    ...(tableProps.isTeach
      ? [
          {
            formattedMessage: <FormattedMessage id="assignments-tab-column-title-teacher" defaultMessage="Assigned By" />,
            propName: 'assignedBy',
            propType: 'string',
            customGetter: (a: AssignmentTableData) => {
              const teacherName = findTeacherTitle(tableProps.teacherList, a.assignedBy);
              return `${teacherName.slice(2)}, ${teacherName.slice(0, 1)}`;
            },
          },
        ]
      : []),
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-start" defaultMessage="Start" />,
      propName: 'started',
      propType: 'date',
      customGetter: (a: AssignmentTableData) => new Date(a.started),
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-due" defaultMessage="Due" />,
      propName: 'deadline',
      propType: 'date',
      customGetter: (a: AssignmentTableData) => new Date(a.deadline),
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-completion" defaultMessage="Completion" />,
      propName: 'completed',
      propType: 'boolean',
      customGetter: (a: AssignmentTableData) => !!a.completedOn,
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-completed" defaultMessage="Completed On" />,
      propName: 'completedOn',
      propType: 'date',
      customGetter: (a: AssignmentTableData) => (a.completedOn ? new Date(a.completedOn) : null),
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-score" defaultMessage="Score" />,
      propName: 'score',
      propType: 'number',
      customGetter: (a: AssignmentTableData) => (typeof a.score === 'number' && a.score >= 0 ? a.score : null),
    },
    {
      formattedMessage: <FormattedMessage id="dependant-assignments-table-duration" defaultMessage="Time Spent" />,
      propName: 'timeSpent',
      propType: 'number',
      customGetter: (a: AssignmentTableData) => (typeof a.timeSpent === 'number' && a.timeSpent > 0 ? a.timeSpent : null),
    },
  ];

  useEffect(() => {
    setAssignments(sortTableData(tableProps.assignments, sortSettings));
  }, [sortSettings, tableProps.assignments]);

  const sortTable = (propName: string, propType: SortingType, customGetter?: any) => {
    if (!propName) {
      throw Error('Missing required propName');
    }
    if (!propType) {
      throw Error('Missing required propType');
    }

    const settings = updateSortSettings(propName, propType, sortSettings, customGetter);
    setSortSettings(settings);

    const isAscending = sortSettings[propName]?.order === TableHeaderSortableSortDirection.ASC;
    mixpanel.track(MixpanelEventTypes.StudentDashboardAssignmentsTableSortChanged, {
      [MixpanelKeys.StudentId]: (routeParams as any).classId,
      [MixpanelKeys.Column]: propName,
      [MixpanelKeys.IsAscending]: isAscending,
    });
  };

  const handleStudentClick = (studentId: string, assignmentId: string, name: string) => {
    mixpanel.track(MixpanelEventTypes.StudentDashboardAssignmentClicked, {
      [MixpanelKeys.StudentId]: studentId,
      [MixpanelKeys.AssignmentId]: assignmentId,
      [MixpanelKeys.AssessmentName]: name,
    });
  };

  return (
    <PageContentResponsive>
      <Widget>
        <Widget.Body>
          <TableResponsive>
            <TableSimple bordered={false} sticky={true} striped={true}>
              <thead>
                <tr>
                  <th>
                    <></>
                  </th>
                  {headers.map(header => (
                    <th key={header.propName} data-testid={`header-${header.propName}`}>
                      <TableHeaderSortable
                        sortDirection={getSortingOrder<TableLabels>(header.propName as TableLabels, sortSettings)}
                        onClick={() => sortTable(header.propName, header.propType as SortingType, header.customGetter)}
                        qa={header.propName}
                      >
                        {header.formattedMessage}
                      </TableHeaderSortable>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody data-testid="assignments-table">
                {assignments.map((assignment: AssignmentTableData, i) => {
                  const { name, started, deadline, completedOn, score } = assignment;
                  const status = getAssignmentStatus(started, deadline);
                  const uniqueKey = `${assignment.assignmentId}-${assignment.deadline}-${i}`;
                  return (
                    <tr key={uniqueKey} data-testid={`assignment-${i}`}>
                      <td data-testid="status">
                        <Tooltipped
                          label={intl.formatMessage({
                            id: `assignments-tab-tooltip-${status}`,
                          })}
                        >
                          <CircularStatusIndicator status={status === AssignmentStatus.PAST} />
                        </Tooltipped>
                      </td>
                      <td data-testid="name">
                        {assignment?.isLegacy ? (
                          assignment.name
                        ) : (
                          <TableCellLink>
                            <Link
                              to={{
                                pathname: `${match.url}/${assignment.assignmentId}/markbook`,
                                state: { from: match.url },
                              }}
                              onClick={() => handleStudentClick(tableProps.studentId, assignment.assignmentId, name)}
                            >
                              {assignment.name}
                            </Link>
                          </TableCellLink>
                        )}
                      </td>
                      <td data-testid="subject">
                        <SubjectName subjectName={assignment.subjectName} />
                      </td>
                      <td data-testid="assignmentType">
                        {assignment.assignmentType === 'essay' ? (
                          <FormattedMessage id="dependant-assignments-table-type-task" defaultMessage="Task" />
                        ) : (
                          <FormattedMessage
                            id="nuggets-count"
                            defaultMessage="{totalNuggets, plural, one {# Nugget} other {# Nuggets}}"
                            values={{ totalNuggets: assignment.totalNuggets }}
                          />
                        )}
                      </td>
                      {tableProps.isTeach && (
                        <td data-private={true} data-testid="assignedBy">
                          {findTeacherTitle(tableProps.teacherList, assignment.assignedBy)}
                        </td>
                      )}
                      <td data-testid="started">{formatDateAndTime(started)}</td>
                      <td data-testid="deadline">{formatDateAndTime(deadline)}</td>
                      <td data-testid="completed">{getCompletionStatus(assignment)}</td>
                      <td data-testid="completedOn">{formatDateAndTime(completedOn)}</td>
                      <td data-testid="score">
                        {typeof score === 'number' && score >= 0 && (
                          <ScorePercentage
                            percentage={Math.round(score)}
                            hasBar={tableProps.showBars}
                            hasNumber={tableProps.showNumbers}
                            hasSymbol={tableProps.showSymbols}
                            hasColouredCell={tableProps.colouredCells}
                          />
                        )}
                      </td>
                      <td>{<DurationMessage duration={assignment.timeSpent ?? 0} />}</td>
                    </tr>
                  );
                })}
              </tbody>
            </TableSimple>
          </TableResponsive>
        </Widget.Body>
      </Widget>
    </PageContentResponsive>
  );
};

const getCompletionStatus = (assignment: AssignmentTableData) => {
  const completedDate = new Date(assignment?.completedOn ?? '');
  const deadlineDate = new Date(assignment.deadline);

  if (!assignment?.completedOn) {
    return (
      <ColorEmphasis variant={ColorEmphasisVariant.WARNING}>
        <span data-testid="not-completed">
          <FormattedMessage id="dependant-assignments-table-not-completed" defaultMessage="Not Completed" />
        </span>
      </ColorEmphasis>
    );
  }

  if (completedDate <= deadlineDate) {
    return (
      <span data-testid="completed">
        <FormattedMessage id="dependant-assignments-table-completed" defaultMessage="Completed" />
      </span>
    );
  }

  return (
    <ColorEmphasis variant={ColorEmphasisVariant.WARNING}>
      <span data-testid="completed-late">
        <FormattedMessage id="dependant-assignments-table-completed-late" defaultMessage="Completed Late" />
      </span>
    </ColorEmphasis>
  );
};

function getAssignmentStatus(startTime?: string, endTime?: string): AssignmentStatus {
  const now = new Date();
  if (startTime && now > new Date(startTime)) {
    if (endTime && now < new Date(endTime)) {
      return AssignmentStatus.ACTIVE;
    } else {
      return AssignmentStatus.PAST;
    }
  } else {
    return AssignmentStatus.PLANNED;
  }
}

const findTeacherTitle = (teachersList: SelectOption[], assignedBy: string) => teachersList.find(t => t.value === assignedBy)?.title ?? '';
