import { AssignmentDetails, MarkbookData, MarkbookSummary } from 'century-core/core-apis/assignment/types';
import { getLabel } from 'century-core/core-apis/contentV2/labels/labels';
import { useDataLoader } from 'century-core/core-utils/hooks/use-data-loader';
import { useAssignmentLabelsTranslation } from '../helpers/hooks';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import { getLateSubmissionInDays, translateLateSubmission } from '../helpers/helpers';
import {
  CdsStatValue,
  CdsStatValueSize,
  ColorEmphasis,
  ColorEmphasisVariant,
  DataRow,
  DataRowItemTypes,
  StatIndicator,
  StatIndicatorSizes,
  StatIndicatorTypes,
  Widget,
} from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { translateYesOrNo } from 'century-core/core-utils/forms/options';
import { allowedMarkdownTypes } from 'century-core/core-utils/utils/markdown';
import ReactMarkdown from 'react-markdown';
import { sanitize } from 'dompurify';
import { formatDateAndTime } from 'century-core/core-utils/utils/date/date';
import { useTranslateSubject } from 'century-core/core-components/Subject/SubjectLabel';
import { AverageScoreIndicatorCell } from './AverageScoreIndicatorCell';
import { QA } from './MultipleStudentsStatsWidget';

type IndividualStudentStatsProps = {
  assignment: AssignmentDetails;
  summary: MarkbookSummary;
  userData: MarkbookData[] | null;
};
export const IndividualStudentStats = (props: IndividualStudentStatsProps) => {
  const { assignment, summary, userData } = props;

  const { data: subject } = useDataLoader((token, signal: AbortSignal) => getLabel(assignment.subjectId, token, signal), true);

  const translation = useAssignmentLabelsTranslation();
  const averageScore = getPercentValue(summary.averageScore);
  const lateSubmissionInDays = getLateSubmissionInDays(assignment);
  const translateSubject = useTranslateSubject();

  return (
    <Widget>
      <Widget.Body>
        <DataRow itemBackgrounds={true}>
          <CompletionStatItem userData={userData} />
          <AverageScoreIndicatorCell score={averageScore} seconds={summary.timeSpent?.seconds} />
        </DataRow>
        <DataRow itemBackgrounds={true}>
          <DataRow.Item qa={`${QA}-subject`}>
            <DataRow.Label>{translation.subject}</DataRow.Label>
            <DataRow.Value qa="assignment-subject">{translateSubject(subject?.name)}</DataRow.Value>
          </DataRow.Item>
          <DataRow.Item status={!assignment.schedule?.startAt ? DataRowItemTypes.WARNING : undefined} qa={`${QA}-start-date`}>
            <DataRow.Label>{translation.startTime}</DataRow.Label>
            <DataRow.Value qa="assignment-start-date">
              {assignment.schedule?.startAt ? (
                formatDateAndTime(assignment.schedule?.startAt)
              ) : (
                <ColorEmphasis variant={ColorEmphasisVariant.SECONDARY}>
                  <FormattedMessage id="no-time-defined-warning-message" defaultMessage="No time defined" />
                </ColorEmphasis>
              )}
            </DataRow.Value>
          </DataRow.Item>
          <DataRow.Item status={!assignment.schedule?.dueAt ? DataRowItemTypes.WARNING : undefined} qa={`${QA}-due-date`}>
            <DataRow.Label>{translation.dueTime}</DataRow.Label>
            <DataRow.Value qa="assignment-due-date">
              {assignment.schedule?.dueAt ? (
                formatDateAndTime(assignment.schedule?.dueAt)
              ) : (
                <ColorEmphasis variant={ColorEmphasisVariant.SECONDARY}>
                  <FormattedMessage id="no-time-defined-warning-message" defaultMessage="No time defined" />
                </ColorEmphasis>
              )}
            </DataRow.Value>
          </DataRow.Item>
        </DataRow>
        <DataRow itemBackgrounds={true}>
          <DataRow.Item qa={`${QA}-instructions`}>
            <DataRow.Label>{translation.instructions}</DataRow.Label>
            <DataRow.Value qa="assignment-instructions">
              {assignment.instructions ? (
                <ReactMarkdown allowedTypes={allowedMarkdownTypes}>{sanitize(assignment.instructions)}</ReactMarkdown>
              ) : (
                <ColorEmphasis variant={ColorEmphasisVariant.SECONDARY}>
                  <FormattedMessage id="no-instructions-defined-warning-message" defaultMessage="No instructions" />
                </ColorEmphasis>
              )}
            </DataRow.Value>
          </DataRow.Item>
        </DataRow>
        <DataRow itemBackgrounds={true}>
          <DataRow.Item qa={`${QA}-multiple-attempts`}>
            <DataRow.Label>{translation.multipleAttempts}</DataRow.Label>
            <DataRow.Value qa="assignment-multiple-attempts">
              <FormattedMessage
                id={translateYesOrNo(assignment.options.reattemptsAllowed)?.messageId}
                defaultMessage={translateYesOrNo(assignment.options.reattemptsAllowed)?.defaultMessage}
              />
            </DataRow.Value>
          </DataRow.Item>
          <DataRow.Item qa={`${QA}-late-submissions`}>
            <DataRow.Label>{translation.lateSubmission}</DataRow.Label>
            <DataRow.Value qa="assignment-late-submissions">
              <FormattedMessage
                id={translateLateSubmission(lateSubmissionInDays).messageId}
                defaultMessage={translateLateSubmission(lateSubmissionInDays).defaultMessage}
              />
            </DataRow.Value>
          </DataRow.Item>
        </DataRow>
      </Widget.Body>
    </Widget>
  );
};

const CompletionStatItem = ({ userData }: { userData: MarkbookData[] | null }) => {
  if (!userData?.[0]?.summary?.completion?.total) {
    return null;
  }

  return (
    <DataRow.Item status={DataRowItemTypes.INFO} qa={`${QA}-completion`}>
      <CdsStatValue size={CdsStatValueSize.MEDIUM}>
        <CdsStatValue.Label>
          <CdsStatValue.Indicator>
            <StatIndicator
              type={StatIndicatorTypes.COMPLETION}
              size={StatIndicatorSizes.MEDIUM}
              score={userData[0].summary.completion.count}
            />
          </CdsStatValue.Indicator>
          <FormattedMessage id="completion" defaultMessage="Completion" />
        </CdsStatValue.Label>
        <CdsStatValue.Value>
          {userData[0].summary.completion.count}
          <CdsStatValue.Total>/ {userData[0].summary.completion.total}</CdsStatValue.Total>
        </CdsStatValue.Value>
      </CdsStatValue>
    </DataRow.Item>
  );
};
