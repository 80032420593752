import { useSubscriptionContext } from 'century-core/core-subscription/hooks/SubscriptionContext';
import { useMemo } from 'react';
import { useLearnerProductContext } from 'century-core/core-subscription/hooks/LearnerProductContext';
import { useB2C } from 'century-core/core-utils/hooks/useB2C';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

export const useB2CMixpanelProperties = () => {
  const isB2C = useB2C()
  const { subscriptionTrackingDetails } = useSubscriptionContext()
  const learnerProductContext = useLearnerProductContext()

  const b2cTrackingDetails = useMemo(() => {
    if (!isB2C) {
      return null;
    }
    if (learnerProductContext?.product) {
      return { [MixpanelKeys.LearnerProductKey]: learnerProductContext?.product?.productKey }
    }
    return subscriptionTrackingDetails
  }, [subscriptionTrackingDetails, learnerProductContext, isB2C])

  return  isB2C ? b2cTrackingDetails : {}
}