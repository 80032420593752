import {
  CompletionNugget,
  CompletionNuggetDetails,
  CompletionNuggetStudentItem,
  getStudentsNuggetData,
} from 'century-core/core-apis/learn/aggregations/aggregations';
import { ExtendedPaginatedResult } from 'century-core/core-apis/assignment/reports';
import { getNuggetHash, mixInNuggetResults, getCompletionNuggets } from '../../api/getNuggetCompletionData';
import { getLabelString } from '../../common/helpers/markbookHelpers';
import { IntlShape } from 'react-intl';
import { QLAData, QLANugget } from './AssignmentQLA';

type Students = { _id: string; username: string };

const getNuggetCompletionData = (token: string, reports: ExtendedPaginatedResult): Promise<CompletionNugget[]> => {
  const selectedNuggets = reports.nuggets.map(({ nuggetId, name }) => ({ _id: nuggetId, name }));
  const completedNuggets = getCompletionNuggets(selectedNuggets);
  const students = reports.data.map(({ user }) => ({ _id: user.userId, name: `${user.details.firstName} ${user.details.lastName}` }));

  return getStudentsNuggetData(token, completedNuggets, students).then(allData =>
    mixInNuggetResults(students, completedNuggets, getNuggetHash(allData))
  );
};

export const processQLAData = async (token: string, reports: ExtendedPaginatedResult, intl: IntlShape): Promise<QLAData> => {
  const nuggetsWithActivity: { [key: string]: QLANugget } = {};
  const studentsWithActivity: string[] = [];
  const students = reports.data.map(r => ({ _id: r.user.userId, username: `${r.user.details.firstName} ${r.user.details.lastName}` }));
  const nuggetCompletionData = await getNuggetCompletionData(token, reports);

  if (!nuggetCompletionData?.length && !students?.length) {
    return { users: [], nuggets: [], nuggetCompletionData: [] };
  }

  const studentRows = getStudentResults(students, nuggetCompletionData, intl);

  nuggetCompletionData.forEach(n => {
    Object.entries(n.students).forEach(([studentId, studentNuggetData]) => {
      if (isCompletionNuggetDetails(studentNuggetData) && !studentNuggetData.lastResult?.date) {
        return;
      }

      if (!studentsWithActivity.includes(studentId)) {
        studentsWithActivity.push(studentId);
      }

      if (nuggetsWithActivity[n._id]) {
        nuggetsWithActivity[n._id].students.push(studentId);
      } else {
        nuggetsWithActivity[n._id] = { id: n._id, name: n.name, students: [studentId] };
      }
    });
  });

  return {
    users: studentRows.filter(v => studentsWithActivity.includes(v.id)),
    nuggets: Object.values(nuggetsWithActivity),
    nuggetCompletionData,
  };
};

const getStudentResults = (students: Students[], completionNuggets: CompletionNugget[], intl: IntlShape) => {
  return students.map(student => {
    const result = { id: student._id, student: student.username };

    completionNuggets.forEach(nugget => {
      const studentResults = nugget.students[student._id];
      if (!studentResults) return;

      const { lastResult, bestResult, averageResult } = studentResults;
      const score = lastResult?.score ?? null;

      const label = getLabelString(lastResult, bestResult, averageResult, nugget, intl);
      result[nugget._id] = { score, label };
    });
    return result;
  });
};

const isCompletionNuggetDetails = (
  data: CompletionNuggetStudentItem | CompletionNuggetDetails | string
): data is CompletionNuggetDetails => {
  return typeof data !== 'string' && typeof data.lastResult !== 'undefined';
};
