import { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SortSettings, updateSortSettings } from 'century-core/core-utils/utils/tableSorting/tableSortingV2';

type SortingDirection = 'asc' | 'desc';

type PaginationParams<T> = {
  itemsPerPage: number;
  totalItems: number;
  orderBy?: string;
  sortBy?: SortingDirection;
  allowedSortColumns?: T[];
  syncWithUrl?: boolean;
};
type SortParams = {
  page?: number;
  sort?: SortingDirection;
  order?: string ;
};

function usePagination<T extends string>({
  itemsPerPage,
  totalItems,
  orderBy,
  sortBy = 'asc',
  allowedSortColumns,
  syncWithUrl = false,
}: PaginationParams<T>) {
  const history = useHistory();
  const location = useLocation();

  const { page, sort, direction, defaultSortSettings } = getInitialSettings(orderBy, sortBy, allowedSortColumns, location.search, syncWithUrl);

  const [currentPage, setCurrentPage] = useState(page);
  const [sortColumn, setSortColumn] = useState(sort);
  const [sortDirection, setSortDirection] = useState(direction);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const updateUrlParams = useCallback((sortParams: SortParams) => {
      if (!syncWithUrl) return;

      const searchParams = new URLSearchParams(window.location.search);
      Object.keys(sortParams).forEach(key => {
        if (sortParams[key] !== undefined) {
          searchParams.set(key, sortParams[key].toString());
        } else {
          searchParams.delete(key);
        }
      });
      history.replace({ search: searchParams.toString() });
    },
    [history, syncWithUrl]
  );

  const goToPage = useCallback((page: number) => {
    if (page < 1 || page > totalPages) return;
    
      setCurrentPage(page);
      updateUrlParams({ page });
    },
    [updateUrlParams, totalPages]
  );

  const handleNextPage = useCallback(() => {
    const nextPage = Math.min(currentPage + 1, totalPages);
    setCurrentPage(nextPage);
    updateUrlParams({ page: nextPage });
  }, [currentPage, totalPages, updateUrlParams]);

  const handlePreviousPage = useCallback(() => {
    const prevPage = Math.max(currentPage - 1, 1);
    setCurrentPage(prevPage);
    updateUrlParams({ page: prevPage });
  }, [currentPage, updateUrlParams]);

  const handleSortingState = useCallback((column: string, direction: 'asc' | 'desc') => {
      setSortColumn(column);
      setSortDirection(direction);
      updateUrlParams({ sort: direction, order: column });
    },
    [updateUrlParams]
  );

  return {
    currentPage,
    setCurrentPage,
    totalPages,
    goToPage,
    handleNextPage,
    handlePreviousPage,
    sortColumn,
    sortDirection,
    handleSortingState,
    defaultSortSettings,
  };
}

export default usePagination;

function getInitialSettings<T extends string | number>(
  defaultOrderBy: string | undefined,
  defaultSortDirection: SortingDirection,
  allowedSortColumns: T[] | undefined,
  search: string,
  syncWithUrl: boolean
) {
  const params = new URLSearchParams(search);
  const sortParam = params.get('sort')
  const sort = allowedSortColumns?.includes(sortParam as T) ? (sortParam) : defaultOrderBy 
  const sortValue = syncWithUrl ? sort : defaultOrderBy;
  const defaultSortSettings = sortValue ? updateSortSettings(sortValue, 'string', { name: { type: 'string' } } as unknown as SortSettings) : {};
  
  if (!syncWithUrl) return { page: 1, sort: defaultOrderBy, direction: defaultSortDirection, defaultSortSettings };

  const orderParam = params.get('order') as SortingDirection;
  const pageParam = Number(params.get('page'));
  const direction = ['asc', 'desc'].includes(orderParam) ? orderParam : defaultSortDirection;
  const page = isNaN(pageParam) || pageParam < 1 ? 1 : pageParam;

  return { page, sort, direction, defaultSortSettings };
}