import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';

// ensures page view event is applied consistent and only occurs one time per component lifecycle
export const usePageViewEvent = (pageName: string, skip?: boolean) => {
  const pageViewRef = useRef(false);

  // resets if page name changes
  useEffect(() => {
    pageViewRef.current = false;
  }, [pageName]);

  useEffect(() => {
    if (!pageViewRef.current && !skip && pageName) {
      pageViewRef.current = true;
      mixpanel.track(MixpanelEventTypes.PageViewed, { [MixpanelKeys.PageName]: pageName });
    }
  }, [pageName, skip]);
};
