import PaginationWidget from '../Pagination/PaginationWidget';

type NavigationOptions = 'Next Page' | 'Previous Page' | `Go to Page ${number}`;

export interface PaginationWithMixpanelProps {
  currentPage: number;
  totalPages: number;
  loadNextPage: () => void;
  loadPreviousPage: () => void;
  loadSpecificPage: (page: number) => void;
  trackPageNavigation: (navigatedValue: NavigationOptions) => void;
}

export const PaginationWithMixpanel = (props: PaginationWithMixpanelProps) => {
  const { totalPages, currentPage, trackPageNavigation, loadNextPage, loadPreviousPage, loadSpecificPage } = props;
  return (
    <PaginationWidget
      maxPages={totalPages}
      currentPage={currentPage}
      onNextClick={() => {
        loadNextPage();
        trackPageNavigation('Next Page');
      }}
      onPrevClick={() => {
        loadPreviousPage();
        trackPageNavigation('Previous Page');
      }}
      goToPage={page => {
        loadSpecificPage(page);
        trackPageNavigation(`Go to Page ${page}`);
      }}
    />
  );
};
