import { handlePromiseRejection } from 'century-core/core-apis/utils/create-fetch-request';
import { TranslationDocument } from 'entities/TranslationDocument/TranslationDocument';
import { AvailableOrgTypes } from '../../century-core/entities/Intl/defaultIntlValues';
import { GET } from 'century-core/core-apis/utils';

export function fetchLocalTranslationMessages(locale: string, orgType: AvailableOrgTypes) {
  const baseUrl = '/translations';
  const url = `${baseUrl}/${orgType}/${locale}.json`;

  return GET<TranslationDocument>({ url }).catch(handlePromiseRejection('localised-errors-cdn-error'));
}
