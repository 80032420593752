import { BoxSimple, Heading, Text, LoadingSpinner, PageContentResponsive, TeacherStudentMarkbook } from '@ctek/design-system';
import { useLocation, useParams } from 'react-router-dom';
import { useDataLoader } from 'century-core/core-utils/hooks/use-data-loader';
import { useCallback, useEffect, useState } from 'react';
import { getAssignmentById } from 'century-core/core-apis/assignment/assignments';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import AssignmentQLA from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentsV3/AssignmentQLA/AssignmentQLA';
import { IndividualStudentTable } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentGroupsList/StudentGroup/Markbook/IndividualStudentTable';
import { useAssignmentQLA } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentsV3/AssignmentQLA/useAssignmentQLA';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { getAssignmentMarkbookReports } from 'century-core/core-apis/assignment/reports';
import { usePaginatedDataLoader } from 'century-core/core-utils/hooks/use-paginated-data-loader';
import { IndividualStudentStats } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardAssignmentsV3/AssignmentMarkbookStats/IndividualStudentStats';
import { useAppHeader } from 'century-core/core-components/AppHeader/AppHeaderContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePageViewEvent } from 'century-core/core-components/MixpanelUtils';

export const StudentAssignmentMarkbookPage = ({ studentId, userMode }: { studentId: string; userMode: DashboardUserMode }) => {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const [showQLA, setShowQLA] = useState(false);
  const [selectedQLA, setSelectedQLA] = useState({ studentQLA: '', nuggetQLA: '' });

  const location = useLocation<{ from: string }>();
  const { addBreadcrumb, removeBreadcrumb } = useAppHeader();
  const { formatMessage } = useIntl();

  usePageViewEvent(
    userMode === DashboardUserMode.GUARDIAN
      ? 'guardian-dependant-assignments-markbook'
      : userMode === DashboardUserMode.STUDENT
      ? 'student-dashboard-assignments-markbook'
      : userMode === DashboardUserMode.TEACHER
      ? 'teacher-student-dashboard-assignments-markbook'
      : ''
  );

  useEffect(() => {
    const previousPath = location.state?.from;
    const title = formatMessage({ id: 'teacher-dashboard-assignments-title', defaultMessage: 'Assignments' });
    if (previousPath) {
      addBreadcrumb({
        path: previousPath,
        title,
      });
    }

    return () => {
      if (previousPath) {
        removeBreadcrumb({
          path: previousPath,
          title,
        });
      }
    };
  }, [addBreadcrumb, formatMessage, removeBreadcrumb, location.state?.from]);

  const getAssignmentData = useCallback(
    async (token: string, signal: AbortSignal) => await getAssignmentById(token, assignmentId, signal),
    [assignmentId]
  );

  const getStudentAssignmentReport = useCallback(
    async (token: string, opts: Record<string, string>, signal) =>
      await getAssignmentMarkbookReports(token, assignmentId, { userIds: [studentId], ...opts }, signal),
    [assignmentId, studentId]
  );
  const {
    data: assignment,
    error: assignmentError,
    loading: loadingAssignment,
    load: loadAssignment,
  } = useDataLoader(getAssignmentData, true);

  const paginatedData = usePaginatedDataLoader(getStudentAssignmentReport, { itemsPerPage: 30 });
  const { data: report, error, loading } = paginatedData;

  const qlaData = useAssignmentQLA(report);

  const openQLA = useCallback((studentQLA: string, nuggetQLA: string) => {
    setShowQLA(true);
    setSelectedQLA({ studentQLA, nuggetQLA });
  }, []);

  if (loading || loadingAssignment) return <LoadingSpinner type="independent" />;
  if (error || assignmentError) return <ErrorReload action={loadAssignment} />;
  if (!assignment) {
    return (
      <BoxSimple>
        <Heading.SectionTitle>
          <FormattedMessage id="generic-no-data" defaultMessage="No data" />
        </Heading.SectionTitle>
        <Text.Message>
          <FormattedMessage id="generic-no-data-message" defaultMessage="There is no data to display." />
        </Text.Message>
      </BoxSimple>
    );
  }

  return (
    <>
      <PageContentResponsive>
        <TeacherStudentMarkbook>
          {report?.summary && <IndividualStudentStats assignment={assignment} summary={report.summary} userData={report.data} />}
          <IndividualStudentTable
            paginatedData={paginatedData}
            assignment={assignment}
            openQLA={openQLA}
            qlaLabels={qlaData?.users || []}
            hasQLA={userMode !== DashboardUserMode.STUDENT}
          />
        </TeacherStudentMarkbook>
      </PageContentResponsive>
      <AssignmentQLA
        qlaData={qlaData}
        selectedQLA={selectedQLA}
        showQLA={showQLA}
        setShowQLA={setShowQLA}
        qa="student-dashboard-assignment-markbook-QLA"
      />
    </>
  );
};
