import { FetchErrorWithData } from 'century-core/core-apis/fetch';
import { GET, setUrlSearchParams } from 'century-core/core-apis/utils';
import { PaginatedResult } from 'century-core/core-utils/shared/shared.types';
import { getAssignmentUrl } from './assignmentV1';
import { AssignmentNugget, MarkbookData, MarkbookSummary } from './types';

export type AssignmentSummaryReport = {
  summary: MarkbookSummary;
  reattemptsAllowed: boolean;
  nuggets: AssignmentReportNuggets[];
};

export type AssignmentReportNuggets = Omit<AssignmentNugget, 'addedAt' | 'addedBy' | 'nugget'> & { name: string };
export type ExtendedPaginatedResult = PaginatedResult<MarkbookData> & AssignmentSummaryReport;

export const getAssignmentMarkbookReports = (
  token: string,
  assignmentId: string,
  opts: Record<string, any>,
  signal: AbortSignal
): Promise<ExtendedPaginatedResult> => {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/reports/markbook`);
  setUrlSearchParams(url, opts);

  return GET<ExtendedPaginatedResult>({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to get assignment markbook reports: ${err.message}`);
  });
};

export const getAssignmentSummary = (token: string, assignmentId: string, signal: AbortSignal) => {
  const url = getAssignmentUrl(`/assignments/${assignmentId}/reports/summary`);

  return GET<AssignmentSummaryReport>({ url, token, signal }).catch((err: FetchErrorWithData) => {
    throw Error(`Failed to get assignment summary: ${err.message}`);
  });
};
