import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

// TODO: add these to types-century
interface AssignmentFeedback {
  name: string;
  subject: string;
  subjectName: string;
  assignmentId: string;
}

interface CompletedAssignment {
  name: string;
  subject: string;
  subjectName: string;
  assignmentType: string;
  totalNuggets: number;
  deadline: string;
  completedOn: string;
  score: number;
  timeSpent: number;
  hasLink: boolean;
  assignmentId: string;
}

export interface AssignmentTableData {
  assignmentId: string;
  name: string;
  subjectId: string;
  subjectName: string;
  assignmentType: 'nuggets' | 'essay';
  totalNuggets: number;
  started: string;
  deadline: string;
  completedOn?: string;
  assignedBy: string;
  score?: number;
  timeSpent?: number;
  hasLink?: boolean;
  isLegacy?: boolean;
}

export function getDueAssignments(accessToken: string) {
  const url = getLearnURL('/assignments/due');

  return GET<Ctek.DueAssignment[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get due assignments, ${err}`);
  });
}

export function getRecentFeedback(accessToken: string) {
  const url = getLearnURL('/assignments/recent');

  return GET<AssignmentFeedback[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get due assignments, ${err}`);
  });
}

export function getCompletedAssignments(accessToken: string) {
  const url = getLearnURL('/assignments/completed');

  return GET<CompletedAssignment[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get due assignments, ${err}`);
  });
}

export function getStudySessionGrading(studySessionId: string, accessToken: string) {
  const url = getLearnURL(`/study-sessions/${studySessionId}/grading`);

  return GET<Ctek.AbacusStudySessionGrading[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get studysession grading ${studySessionId}, ${err}`);
  });
}

export function getAssignmentsByUserId(userId: string, token: string, signal?: AbortSignal) {
  const url = getLearnURL(`/assignments/user/${userId}`);

  return GET<AssignmentTableData[]>({ url, token, signal }).catch((err: Error) => {
    throw Error(`Unable to get assignments for user ${userId}, ${JSON.stringify(err)}`);
  });
}
