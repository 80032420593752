import { DataRow, DataRowItemTypes } from '@ctek/design-system';

type DataIemProps = {
  label: React.ReactNode;
  value: React.ReactNode;
  status?: DataRowItemTypes;
  qa?: string;
};

export const DataItem = ({ label, value, qa, status = DataRowItemTypes.DEFAULT }: DataIemProps) => {
  return (
    <DataRow.Item status={status} qa={qa}>
      <DataRow.Label>{label}</DataRow.Label>
      <DataRow.Value>{value}</DataRow.Value>
    </DataRow.Item>
  );
};
