import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import mixpanel from 'mixpanel-browser';

export const trackMoodSelected = (
  elaArea: string,
  emotion: string,
  emotionId: string,
) => {
  mixpanel.track(MixpanelEventTypes.ElaMoodSelected, {
    [MixpanelKeys.ElaArea]: elaArea,
    [MixpanelKeys.Emotion]: emotion,
    [MixpanelKeys.EmotionId]: emotionId,
  });
};
