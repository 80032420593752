import { GET } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

export async function fetchLabels(type: string, token: string): Promise<Ctek.Label[]> {
  const url = getContentURL('/labels/search');
  url.search += `&type=${type}`;
  url.search += '&select=_id,name,translationKey';

  return GET<Ctek.Label[]>({ url, token }).catch((err: Error) => {
    throw Error(`Unable to get subjects or levels labels, ${err}`);
  });
}

export function getLabel(labelId: string, accessToken: string, signal: AbortSignal): Promise<Ctek.Label> {
  const url = getContentURL(`/labels/${labelId}`);

  return GET<Ctek.Label>({ url, token: accessToken, signal }).catch((err: Error) => {
    throw Error(`Unable to get label by id ${labelId}, ${err}`);
  });
}

export async function fetchLabelsByIds(labelIds: string[], token: string, signal?: AbortSignal) {
  const url = getContentURL('/labels');
  url.searchParams.set('ids', labelIds.join());
  url.searchParams.set('select', '_id,name,translationKey');
  url.search = decodeURIComponent(url.search);

  return GET<Ctek.Label[]>({ url, token, signal }).catch((err: Error) => {
    throw Error(`Unable to get labels by ids, ${err.message}`);
  });
}
