import React, { useCallback, useReducer } from 'react';
import { IconTypes, MenuItemData, ButtonVariant } from '@ctek/design-system';
import {
  appHeaderReducer,
  emptyAppHeaderState,
  appHeaderInitialiser,
  AppHeaderReducerState,
  AppHeaderActions,
  AppHeaderAction,
} from './appHeaderReducer';

export interface PageButtonsItem {
  paths: string[];
  onClick: (evt: React.MouseEvent) => void;
  title?: React.ReactNode;
  qa?: string;
  variant?: ButtonVariant;
  hidden?: boolean;
}

interface MenuItemDataWithIcon extends MenuItemData {
  icon?: IconTypes;
}

export type Intl = { id: string; defaultMessage?: string; values?: any };

export interface Breadcrumbs {
  items: BreadcrumbItem[];
}

export interface BreadcrumbItem {
  path: string;
  title?: string;
  titleRightNode?: React.ReactNode;
}
export interface PageControlsItem {
  paths: string[];
  controls: MenuItemDataWithIcon[];
}
export interface PageControls {
  items: PageControlsItem[];
}

export interface CtekAppHeaderContext {
  addPageControls: (newControls: PageControlsItem) => void;
  removePageControls: (removePaths: string[]) => void;
  addPagePrimaryButton: (newButton: PageButtonsItem) => void;
  removePagePrimaryButton: (removePaths: string[]) => void;
  addPageDefaultButton: (newButton: PageButtonsItem) => void;
  removePageDefaultButton: (removePaths: string[]) => void;
  addBreadcrumb: (breadcrumb: BreadcrumbItem) => void;
  removeBreadcrumb: (breadcrumb: BreadcrumbItem) => void;
  clearBreadcrumbs: () => void;
  breadcrumbs: Breadcrumbs;
  pageControls: PageControls;
  pagePrimaryButtons: { items: PageButtonsItem[] };
  pageDefaultButtons: { items: PageButtonsItem[] };
}

const AppHeaderContext = React.createContext<CtekAppHeaderContext>({} as unknown as CtekAppHeaderContext);

export const useAppHeader = () => React.useContext(AppHeaderContext);

interface AppHeaderProviderProps {
  initialState?: AppHeaderReducerState;
  children: React.ReactNode;
}

export const AppHeaderProvider = (props: AppHeaderProviderProps) => {
  const [{ breadcrumbs, pageControls, pagePrimaryButtons, pageDefaultButtons }, dispatch] = useReducer<
    (state: AppHeaderReducerState, action: AppHeaderAction) => AppHeaderReducerState,
    AppHeaderReducerState
  >(appHeaderReducer, { ...(props.initialState || emptyAppHeaderState) }, appHeaderInitialiser);

  const addBreadcrumb = useCallback((newBreadcrumb: BreadcrumbItem) => {
    dispatch({ type: AppHeaderActions.ADD_BREADCRUMB, breadcrumb: newBreadcrumb });
  }, []);

  const removeBreadcrumb = useCallback((breadcrumbToRemove: BreadcrumbItem) => {
    dispatch({ type: AppHeaderActions.REMOVE_BREADCRUMB, breadcrumb: breadcrumbToRemove });
  }, []);

  const clearBreadcrumbs = useCallback(() => {
    dispatch({ type: AppHeaderActions.CLEAR_BREADCRUMBS });
  }, []);

  const addPageControls = useCallback((newPageControls: PageControlsItem) => {
    dispatch({ type: AppHeaderActions.ADD_PAGE_CONTROLS, control: newPageControls });
  }, []);

  const removePageControls = useCallback((paths: string[]) => {
    dispatch({ type: AppHeaderActions.REMOVE_PAGE_CONTROLS, paths });
  }, []);

  const addPagePrimaryButton = useCallback((newPrimaryButton: PageButtonsItem) => {
    dispatch({ type: AppHeaderActions.ADD_PAGE_PRIMARY_BUTTON, primaryButton: newPrimaryButton });
  }, []);

  const removePagePrimaryButton = useCallback((paths: string[]) => {
    dispatch({ type: AppHeaderActions.REMOVE_PAGE_PRIMARY_BUTTON, paths });
  }, []);
  const addPageDefaultButton = useCallback((newDefaultButton: PageButtonsItem) => {
    dispatch({ type: AppHeaderActions.ADD_PAGE_DEFAULT_BUTTON, defaultButton: newDefaultButton });
  }, []);

  const removePageDefaultButton = useCallback((paths: string[]) => {
    dispatch({ type: AppHeaderActions.REMOVE_PAGE_DEFAULT_BUTTON, paths });
  }, []);

  return (
    <AppHeaderContext.Provider
      value={{
        addBreadcrumb,
        removeBreadcrumb,
        clearBreadcrumbs,
        addPageControls,
        removePageControls,
        addPagePrimaryButton,
        removePagePrimaryButton,
        addPageDefaultButton,
        removePageDefaultButton,
        breadcrumbs,
        pageControls,
        pagePrimaryButtons,
        pageDefaultButtons,
      }}
    >
      {props.children}
    </AppHeaderContext.Provider>
  );
};

export default AppHeaderContext;
